import React, {useState} from 'react';
import {Grid, Typography, Button, useMediaQuery, Box} from '@mui/material';
import heroImage from '../../../assets/images/landing-page-hero.svg';
import './landing-page.scss';
import {ROUTES} from "../../../global/constant";
import {useNavigate} from 'react-router-dom';
import {useRentCpuHandler} from '../../../global/helper';

function LandingPage({ual, loggedInUser}) {
    const handleRentCpuClick = useRentCpuHandler(loggedInUser);
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [menuOpen, setMenuOpen] = useState(false);

    const login = () => {
        ual.showModal();
    };

    const handleMenuClick = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <div className='grow p-12 bg-background text-white mt-12'>
            <Grid container spacing={4} alignItems='center' justifyContent='center' style={{height: '100%'}}>
                <Grid item xs={12} md={5}>
                    <div style={{marginBottom: '16px'}}>
                        <Typography variant={isSmallScreen ? 'h4' : 'h2'} className='font-pt-serif' style={{marginBottom: '8px', fontWeight: '700'}}>
                            Experience the WAX
                            in a new way with
                            our tools
                        </Typography>
                        <Grid item xs={12} md={6}>
                            <img className='show-on-sm' src={heroImage} alt='Hero' style={{width: '100%', height: 'auto'}} />
                        </Grid>
                        <Typography variant='subtitle1' style={{marginBottom: '8px', color: '#C3C1C1'}} className='font-pt-serif'>
                            Optimize your WAX experience with rapid transaction discovery and hassle-free
                            CPU Rentals.
                        </Typography>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' className='landing-page-button' onClick={() => navigate(ROUTES.TRANSACTIONS ?? "/transactions")}>
                                Trace MyWAX
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' className='landing-page-button' onClick={(e) => handleRentCpuClick(e)}>
                                Rent Cpu
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img className='hide-on-sm' src={heroImage} alt='Hero' style={{width: '100%', height: 'auto', marginLeft: '16px'}} />
                </Grid>
            </Grid>
        </div>
    );
}

export default LandingPage;