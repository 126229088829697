import React from 'react';
import {Modal, Box, Typography, Button, TextField} from '@mui/material';
import CsvUpload from "../../../../assets/images/csv-upload.png"
import csvTemplates from "../../../../assets/csv-template/csvTemplate.csv"
import {CSVLink} from "react-csv";

const ImportModal = ({openImportModel, handleCloseImportModal, handleDragOver, handleDrop, handleFileInputChange, csvTemplate}) => {
    const headers = [
        {label: "Enter Wallets Below", key: "wallets"}
    ];

    const data = [
        {wallets: "nzoeg.wam"},
        {wallets: "xlxoa.c.wam"},
        {wallets: "thestonkcity"},
        {wallets: "waxptoolsadm"},
        {wallets: "waxptoolsown"}
    ];

    return (
        <Modal
            open={openImportModel}
            onClose={handleCloseImportModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal">
                <div className='flex-between'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Import File
                    </Typography>
                    <Button onClick={handleCloseImportModal} className="close-btn">X</Button>
                </div>
                <hr style={{marginBottom: '2rem'}} />
                <div
                    className="flex-center-column"
                    id="modal-modal-description"
                    style={{
                        paddingTop: "2rem",
                        border: "1px dotted #5E4991",
                        borderSpacing: "2rem",
                        borderRadius: "9px",
                        paddingBottom: "2rem",
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                ><label htmlFor="file-input" className="flex-center-column">
                        <img src={CsvUpload} alt="" style={{width: "3rem"}} />
                        <Typography color="white" style={{paddingTop: "2rem"}}>
                            Select CSV file to upload
                        </Typography>

                        <Typography variant="caption" style={{color: "#858484"}}>
                            or drag and drop it here
                        </Typography>
                        <input
                            id="file-input"
                            type="file"
                            accept=".csv"
                            style={{display: "none"}}
                            onChange={handleFileInputChange}
                        />
                    </label>
                </div>
                <div className='flex-center-column width-full'>
                    <Typography color="white" className='width-full' style={{paddingTop: '1.5rem', paddingBottom: '1rem'}}>Or Upload from a URL</Typography>
                    <div className='soft-border width-full' style={{display: 'flex', justifyContent: 'space-between', height: '3rem'}}>
                        <TextField variant="outlined" style={{marginRight: '8px'}} />
                        <Button variant="contained" style={{background: '#5E4991'}}>
                            Upload
                        </Button>
                    </div>
                    <CSVLink data={data} headers={headers} style={{paddingTop: '0.5rem', color: 'white'}} filename={"csv-template.csv"}>
                        Download CSV Template
                    </CSVLink>
                </div>
            </Box>
        </Modal>
    );
}

export default ImportModal;