import {Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "../../../../assets/images/plus.png";
import React from "react";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Tooltip from '@mui/material/Tooltip';
import "../scss/pro-mode.scss"

export function ModalButtons(props) {
    return <>
        <Button onClick={props.onClick1} variant="outlined" size="small"
            style={{border: "1px solid #B093F6", padding: '0.5rem'}}>
            <CloudUploadIcon style={{color: "#B093F6"}} />
            <div style={{color: "#B093F6", paddingLeft: "0.5rem", fontWeight: "bold"}}>Import</div>
        </Button>
        <Button onClick={props.onClick2} variant="outlined" size="small"
            style={{border: "1px solid var(--golden)", padding: '0.5rem', marginLeft: '0.5rem'}}>
            <img src={AddIcon} alt="" />
            <div style={{color: "var(--golden)", paddingLeft: "0.5rem", fontWeight: "bold"}}>Add customers</div>
        </Button>
        <Tooltip title="Clear All">
            <ClearAllIcon onClick={props.onClick} style={{color: "#B093F6", paddingLeft: "0.5rem", height: "3rem", width: "3rem"}} />
        </Tooltip>
    </>;
}