import React, {useState} from 'react';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, FormControlLabel, Tooltip, Checkbox} from '@mui/material';
import ReactJson from 'react-json-view-with-toggle';

const CountTable = ({data, selectedField, selectedOnField}) => {
    const [isJsonCollapsed, setisJsonCollapsed] = useState(false);
    const handleToggleJson = () => {
        setisJsonCollapsed(!isJsonCollapsed);
    };
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6">{selectedField}</Typography>
                        </TableCell>
                        <TableCell>
                            <FormControlLabel
                                control={<Tooltip title="Toggle all JSON data">
                                    <div className="checkbox-wrapper">
                                        <Checkbox checked={!isJsonCollapsed} onChange={handleToggleJson} />
                                    </div>
                                </Tooltip>}
                                label={<Typography variant="h6">Count On {selectedOnField}</Typography>}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        const key = Object.keys(item)[0];
                        const countsData = item[key];
                        return (
                            <TableRow key={index}>
                                <TableCell>{key.split('->')[1]}</TableCell>
                                <TableCell>
                                    <ReactJson src={countsData} collapsed={isJsonCollapsed} theme={'tomorrow'} name={false} style={{color: 'white', background: 'transparent'}} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CountTable;
