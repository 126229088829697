import React from "react";
import { Container, Typography, Button } from '@mui/material';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo)
    }
    handleReload = () => {
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<Container maxWidth="md" style={{ paddingTop: '2rem' }}>
                <Typography variant="h1" className="custom-font" align="center" color='white' gutterBottom>
                    OOPS!
                </Typography>
                <Typography variant="h1" className="custom-font" align="center" color='white' gutterBottom>
                    SOMETHING
                </Typography>
                <Typography variant="h1" className="custom-font" align="center" color='white' gutterBottom>
                    WENT
                </Typography>
                <Typography variant="h1" className="custom-font" align="center" color='white' gutterBottom>
                    WRONG
                </Typography>
                <Typography variant="h5" align="center" color='white' gutterBottom>
                    Please try again later or contact support.
                </Typography>
                <Button variant="contained" onClick={this.handleReload} style={{ display: 'block', margin: 'auto' }} >
                    Reload
                </Button>
            </Container>)
        }

        return this.props.children;
    }
}

export default ErrorBoundary