import {useState, useEffect} from 'react';
import {WaxApi} from '../../../global/api/wax-api';
import './transactions.scss'
import {Typography, Grid, TextField, Button, InputAdornment, useMediaQuery, Tooltip} from '@mui/material'
import ActionsTable from './actions-table';
import AutocompleteWithLabels from './autocomplete';
import FilteredTable from './filtered-table';
import SearchIcon from '@mui/icons-material/Search';
import CountTable from './group-by-tables/count-table';
import SumTable from './group-by-tables/sum-table';
import UniqueTable from './group-by-tables/unique-table';
import CustomAccordion from '../../common-components/accordian/accordian';
import LinearProgressLoader from '../../common-components/linear-progress/linear-progress';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function Transactions({ual, loggedInUser}) {
    const smBreakpoint = useMediaQuery('(max-width:600px)');
    const waxApi = new WaxApi();
    const [accountName, setAccountName] = useState(loggedInUser || "waxptoolsown");
    const [accountNameHolder, setAccountNameHolder] = useState(loggedInUser || "waxptoolsown");
    const [transactions, setTransactions] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const [selectedType, setSelectedType] = useState('count');
    const [selectedTypeForTable, setSelectedTypeForTable] = useState('count');
    const [selectedField, setSelectedField] = useState('act.name');
    const [selectedOnField, setSelectedOnField] = useState('act.data.amount');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [allActions, setAllActions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [insightsProgress, setInsightsProgress] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [totalRecords, setTotalRecords] = useState(null);
    const [filters, setFilters] = useState({
        memo: '',
        date: '',
        from: '',
        to: '',
        action: '',
        contract: '',
    });

    useEffect(() => {
        getTotalActions(accountName);
        getActions(accountName);
        setAllActions([]);
        setGroupedData([]);
        setInsightsProgress(null);
    }, [loggedInUser, accountName]);

    useEffect(() => {
        applyFilters();
    }, [transactions, filters]);

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const mapActionNamesToSendOrReceive = (data) => {
        for (const entry of data) {
            if (entry.act && entry.act.name === "transfer") {
                if (entry.act.data.from === accountName) {
                    entry.act.name = "Send Token";
                } else if (entry.act.data.to === accountName) {
                    entry.act.name = "Receive Token";
                }
            }
        }
    };

    function convertToOldFormat(newFormatObject) {
        const {data, receipts, ...rest} = newFormatObject;
        const isPostResponse = newFormatObject.hasOwnProperty("account_action_seq");
        const oldFormatObject = {
            "timestamp": isPostResponse ? newFormatObject.block_time : newFormatObject.timestamp,
            "trx_id": isPostResponse ? newFormatObject.action_trace.trx_id : newFormatObject.transaction_id,
            "act": {
                "account": isPostResponse ? newFormatObject.action_trace.act.account : newFormatObject.contract,
                "name": isPostResponse ? newFormatObject.action_trace.act.name : newFormatObject.action,
                "authorization": [
                    {
                        "actor": isPostResponse ? newFormatObject.action_trace.act.authorization[0].actor : newFormatObject.actors.split("@")[0],
                    },
                ],
                "data": isPostResponse ? newFormatObject.action_trace.act.data : {...data},
            },
        };
        return oldFormatObject;
    }

    function convertArrayToOldFormat(newFormatArray) {
        if (!Array.isArray(newFormatArray)) {
            return [];
        }
        return newFormatArray.map(convertToOldFormat);
    }

    function groupByNestedField(inputData, groupByField) {
        const groupedData = inputData.reduce((result, item) => {
            const fieldNames = groupByField.split('.');
            let fieldValue = item;
            for (const fieldName of fieldNames) {
                if (Array.isArray(fieldValue)) {
                    fieldValue = fieldValue[0][fieldName];
                } else {
                    fieldValue = fieldValue[fieldName];
                }
                if (fieldValue === undefined) {
                    fieldValue = "N/A";
                }
            }
            if (!result[fieldValue]) {
                result[fieldValue] = [];
            }
            result[fieldValue].push(item);
            return result;
        }, {});
        return groupedData;
    }

    function processGroupedData(groupedData, type, onField) {
        function getNestedValue(obj, path) {
            const parts = path.split('.');
            let value = obj;
            for (const part of parts) {
                value = value[part];
                if (value === undefined) {
                    return undefined;
                }
            }
            return value;
        }
        if (type === "count") {
            const countData = Object.keys(groupedData).map((key) => {
                const counts = groupedData[key].reduce((acc, item) => {
                    const fieldValue = getNestedValue(item, onField);
                    acc[fieldValue] = (acc[fieldValue] || 0) + 1;
                    return acc;
                }, {});

                return {
                    [`Counts of ${onField} on account->${key}`]: counts,
                };
            });
            return countData;
        }
        if (type === "unique") {
            const uniqueData = Object.keys(groupedData).map((key) => ({
                [`unique ${onField}`]: key,
            }));
            return uniqueData;
        }
        if (type === "sum") {
            const sumData = Object.keys(groupedData).map((key) => {
                const sum = groupedData[key].reduce((acc, item) => {
                    const fieldValue = getNestedValue(item, onField);
                    const fieldValueNumber = parseFloat(fieldValue);
                    return typeof fieldValueNumber === "number" ? acc + fieldValueNumber : acc;
                }, 0);
                return {[`Sum of ${onField} on account->${key} is`]: isNaN(sum) ? `The field (${onField}) does not exist in the provided data.` : sum};
            });
            return sumData;
        }
        return [];
    }

    const getAllActions = async (accountName) => {
        let allActions = [];
        let skip = 0;
        let totalActions = 0;
        const {memo, initiator, from, to, action, contract} = filters;
        do {
            const actionsPromise = waxApi.getActionHistory(accountName, action, contract, initiator, skip, toDate, fromDate, from, to);
            const actions = await actionsPromise;
            const newFormat = convertArrayToOldFormat(actions.simple_actions);
            mapActionNamesToSendOrReceive(newFormat);
            allActions = allActions.concat(newFormat);
            totalActions = actions.total.value;
            skip += 1000;
            const progress = (skip / totalActions) * 100;
            setInsightsProgress(progress > 100 ? 100 : progress);
            await actionsPromise;
        } while (skip < totalActions);
        setAllActions(allActions);
        return allActions;
    };

    const getActions = async (accountName, skip = 0) => {
        try {
            setLoading(true);
            setTransactions([])
            const actions = await waxApi.getActionHistory(accountName, '', '', '', skip);
            const newFormat = convertArrayToOldFormat(actions.simple_actions);
            mapActionNamesToSendOrReceive(newFormat);
            setTransactions(newFormat);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setTransactions([]);
        }
    };

    const getTotalActions = async (accountName) => {
        try {
            const actions = await waxApi.getActionHistoryWithPost(accountName, -1)
            const totalActions = actions[0]?.account_action_seq
            setTotalRecords(totalActions)
            return totalActions
        } catch (error) {
            console.error(error);
        }
    };

    const getTableData = async (accountName, pageToJump, rowsPerPage) => {
        setLoading(true);
        setTransactions([])
        const totalEntries = await getTotalActions(accountName);
        const lastEntryPosition = totalEntries - (pageToJump - 1) * rowsPerPage;
        try {
            const pageData = await waxApi.getActionHistoryWithPost(accountName, lastEntryPosition, rowsPerPage)
            const reversedPageData = pageData.reverse()
            const newFormat = convertArrayToOldFormat(reversedPageData);
            mapActionNamesToSendOrReceive(newFormat);
            setTransactions(newFormat);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    const handleFetchTransactions = async () => {
        try {
            setLoading(true);
            setTransactions([])
            setAllActions([]);
            setGroupedData([]);
            setInsightsProgress(null);
            setFilters((prevFilters) => ({
                ...prevFilters,
                ["memo"]: "",
                ["to"]: "",
                ["from"]: "",
            }));
            const {memo, initiator, from, to, action, contract} = filters;
            const filteredQueryResponse = await waxApi.getActionHistory(accountName, action, contract, initiator, 0, toDate, fromDate);
            const newFormat = convertArrayToOldFormat(filteredQueryResponse.simple_actions);
            mapActionNamesToSendOrReceive(newFormat);
            setTransactions(newFormat);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const processInsights = async (allActionsArray) => {
        try {
            const nestedFieldToGroupBy = selectedField;
            const groupedByNestedField = groupByNestedField(allActionsArray, nestedFieldToGroupBy);
            const type = selectedType;
            const onField = selectedOnField
            const processedData = processGroupedData(groupedByNestedField, type, onField);
            setGroupedData(processedData);
        } catch (error) {
            console.error(error);
        }
    };

    const getInsights = async () => {
        setInsightsProgress(1);
        const allActionsArray = await getAllActions(accountName);
        await processInsights(allActionsArray);
        setSelectedTypeForTable(selectedType);
    };

    const handleOptionSelected = (option) => {
        if (option.value === "unique" || option.value === "count" || option.value === "sum") {
            setSelectedType(option.value);
        } else {
            setSelectedField(option.value);
        }
    };

    const applyFilters = () => {
        try {
            const filtered = transactions.filter((transaction) => {
                const {memo, from, to} = filters;
                const transactionMemo = (transaction.act.data?.memo || '').toLowerCase();
                const transactionFrom = (transaction.act.data?.from || '').toLowerCase();
                const transactionTo = (transaction.act.data?.to || '').toLowerCase();
                return (
                    transactionMemo.includes(memo.toLowerCase()) &&
                    transactionFrom.includes(from.toLowerCase()) &&
                    transactionTo.includes(to.toLowerCase())
                );
            });
            setFilteredTransactions(filtered);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const options = [
        {title: 'Contract', value: 'act.account'},
        {title: 'Initiator', value: 'act.authorization.actor'},
        {title: 'From', value: 'act.data.from'},
        {title: 'To', value: 'act.data.to'},
        {title: 'Action', value: 'act.name'},
    ];

    const typeOptions = [
        {title: 'Count', value: 'count'},
        {title: 'Unique', value: 'unique'},
        {title: 'Sum', value: 'sum'},
    ];

    const sampleObjectForCustomers = {
        "act": {
            "account": "eosio.token",
            "name": "transfer",
            "authorization": [
                {
                    "actor": "waxptoolsown"
                }
            ],
            "data": {
                "from": "waxptoolsown",
                "to": "desolator211",
                "amount": 0.114576,
                "symbol": "WAX",
                "memo": "Referral Earnings",
                "quantity": "0.11457600 WAX"
            }
        },
    }
    const formatJson = (jsonObject) => JSON.stringify(jsonObject, null, 2);
    const tooltipContent = (
        <div style={{maxWidth: "400px"}}>
            {<pre>{formatJson(sampleObjectForCustomers)}</pre>}
            <p>This is a sample object. You can enter fields like <code>act.data.amount</code></p>
            <p><code>act.name</code></p>
        </div>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{marginTop: '2rem', color: "white"}}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" className='margin-left' style={{marginBottom: '2rem'}}>TRANSACTIONS</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{paddingRight: '1.2rem'}}>
                        <Grid container spacing={0} justifyContent={smBreakpoint ? "flex-start" : "flex-end"}>
                            <TextField
                                className='margin-right'
                                name='accountName'
                                placeholder='Account Name'
                                value={accountNameHolder} onChange={(e) => setAccountNameHolder(e.target.value)} onBlur={(e) => {setAccountName(accountNameHolder)}}
                                size="small"
                                InputProps={
                                    {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{color: "white"}} />
                                            </InputAdornment>
                                        ),
                                        style: {fontSize: '20px'}
                                    }
                                }
                                style={{border: "1px solid grey", borderRadius: "4px"}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <div className='margin-horizontal' style={{marginTop: '2rem', color: "white", marginBottom: '8rem'}}>
                    <div>
                        <div className='hide-on-lg show-on-sm' style={{marginBottom: '1rem'}}>
                            <CustomAccordion title="Filters">
                                <Grid container spacing={1}>
                                    {[{label: 'Contract', name: 'contract'}, {label: 'Action', name: 'action'}, {label: 'Initiator', name: 'initiator'}].map(({label, name}) => (
                                        <Grid item xs={12} sm={6} key={name}>
                                            <Grid container spacing={1} alignItems="center" xs={12}>
                                                <Grid item xs={4}>
                                                    <label htmlFor={name}>
                                                        <Typography variant="h6" className="inline">
                                                            {label}:
                                                        </Typography>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField name={name} value={filters[name]} onChange={handleFilterChange} variant="filled" size="small" fullWidth />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Button variant="contained" onClick={handleFetchTransactions}>
                                                Fetch transactions
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomAccordion>
                        </div>
                        <Grid container spacing={1} className='hide-on-sm'>
                            {[{label: 'Contract', name: 'contract', description: ' Enter the name of the smart contract associated with the transaction you want to retrieve. (e.g., eosio.token)'}, {label: 'Action', name: 'action', description: 'Specify the type of action you want to fetch (e.g., send, receive, transfer, etc) from the transaction.'}, {label: 'Initiator', name: 'initiator', description: 'Enter the account that initiated or executed the transaction.(e.g., waxptoolsadm)'}].map(({label, name, description}) => (<Grid item xs={12} sm={6} key={name}>
                                <Grid container spacing={1} alignItems='center' xs={12}>
                                    <Grid item xs={4}>
                                        <label htmlFor={name}>
                                            <Typography variant='h6' className='inline'>
                                                {label}:
                                            </Typography>
                                        </label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField name={name} value={filters[name]} onChange={handleFilterChange} variant='filled' size='small' fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Tooltip title={description}>
                                                            <HelpOutlineIcon style={{color: 'grey', cursor: 'pointer'}} />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            ))}
                            {['From', 'To'].map((field, index) => (
                                <Grid item xs={6} key={index}>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={4}>
                                            <label htmlFor={field}>
                                                <Typography variant='h6' className='inline'>
                                                    {field}:
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <DateTimePicker
                                                className='customDateTimePicker'
                                                id={field}
                                                value={field === 'From' ? fromDate : toDate}
                                                onChange={field === 'From' ? handleFromDateChange : handleToDateChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container justifyContent="center" alignItems="center" >
                                <Grid item>
                                    <Button variant="contained" onClick={handleFetchTransactions}>
                                        Fetch transactions
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <hr className='hide-on-sm' style={{borderColor: "#969696", height: "1px", marginBottom: '1rem'}}></hr>
                    <div>
                        <div className='hide-on-lg show-on-sm' style={{marginBottom: '2rem'}}>
                            <CustomAccordion title="Group By">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item xs={4}>
                                                <label htmlFor="Field">
                                                    <Typography variant='h6' style={{color: '#8dc0af'}} className='inline'>
                                                        Field:
                                                    </Typography>
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField name="field" value={selectedField} onChange={(e) => setSelectedField(e.target.value)} variant='filled' size='small' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <Tooltip
                                                                    title={tooltipContent}
                                                                    enterTouchDelay={0}
                                                                >
                                                                    <HelpOutlineIcon style={{color: 'grey', cursor: 'pointer'}} />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <AutocompleteWithLabels label="Type" options={typeOptions} onOptionSelected={handleOptionSelected} />
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} key="OnField">
                                            <Grid container spacing={1} alignItems='center' xs={12}>
                                                <Grid item xs={4}>
                                                    <label htmlFor="OnField">
                                                        <Typography variant='h6' style={{color: '#8dc0af'}} className='inline'>
                                                            OnField:
                                                        </Typography>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={8} style={{paddingLeft: '1rem'}}>
                                                    <TextField name="onField" value={selectedOnField} onChange={(e) => setSelectedOnField(e.target.value)} variant='filled' size='small' fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    <Tooltip
                                                                        title={tooltipContent}
                                                                        enterTouchDelay={0}
                                                                    >
                                                                        <HelpOutlineIcon style={{color: 'grey', cursor: 'pointer'}} />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" alignItems="center" >
                                        <Grid item>
                                            <Button variant="contained" onClick={() => getInsights()}>
                                                Get Insights
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomAccordion>
                        </div>
                        <Grid container spacing={2} className='hide-on-sm'>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6" color="primary.golden">Group By</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={4}>
                                        <label htmlFor="Field">
                                            <Typography variant='h6' style={{color: '#8dc0af'}} className='inline'>
                                                Field:
                                            </Typography>
                                        </label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField name="field" value={selectedField} onChange={(e) => setSelectedField(e.target.value)} variant='filled' size='small' fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Tooltip
                                                            title={tooltipContent}
                                                            enterTouchDelay={0}
                                                        >
                                                            <HelpOutlineIcon style={{color: 'grey', cursor: 'pointer'}} />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutocompleteWithLabels label="Type" options={typeOptions} onOptionSelected={handleOptionSelected} />
                            </Grid>
                            <Grid container spacing={1} style={{paddingLeft: '1rem'}}>
                                <Grid item xs={12} sm={6} key="OnField">
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={4} >
                                            <label htmlFor="OnField">
                                                <Typography variant='h6' style={{color: '#8dc0af'}} className='inline'>
                                                    OnField:
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField name="onField" value={selectedOnField} onChange={(e) => setSelectedOnField(e.target.value)} variant='filled' size='small' fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Tooltip
                                                                title={tooltipContent}
                                                                enterTouchDelay={0}
                                                            >
                                                                <HelpOutlineIcon style={{color: 'grey', cursor: 'pointer'}} />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center" >
                                <Grid item>
                                    <Button variant="contained" onClick={() => getInsights()}>
                                        Get Insights
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    {groupedData.length === 0 ? (
                        <div style={{marginTop: '2rem'}} className='hide-on-md' >
                            <Grid container spacing={0} justifyContent="flex-end">
                                {[{label: "Memo", name: "memo", startAdornment: <SearchIcon />}, {label: "From", name: "from"}, {label: "To", name: "to"}].map(({label, name, startAdornment}) => (
                                    <Grid item xs={12} sm={2} key={name}>
                                        <TextField
                                            name={name}
                                            placeholder={label}
                                            value={filters[name]}
                                            onChange={handleFilterChange}
                                            size="small"
                                            InputProps={
                                                name === "memo"
                                                    ? {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon style={{color: "white"}} />
                                                            </InputAdornment>
                                                        ),
                                                        style: {fontSize: "14px"}
                                                    }
                                                    : {style: {fontSize: "14px"}}
                                            }
                                            style={{border: "1px solid grey", borderRadius: "4px"}}
                                            fullWidth
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>) : null}
                    {insightsProgress && <LinearProgressLoader loading={true} progress={insightsProgress} title={insightsProgress < 50 ? `Fetching All Transactions` : insightsProgress === 50 ? `Loading` : insightsProgress === 100 ? `Apply filters to get Insights` : `Cooking Insights`} />}
                    {groupedData.length === 0 ? (
                        <ActionsTable loading={loading} filteredTransactions={filteredTransactions} getActions={getActions} accountName={accountName} getTableData={getTableData} totalRecords={totalRecords} />) : selectedTypeForTable === "count" ? (
                            <CountTable data={groupedData} selectedField={selectedField} selectedOnField={selectedOnField} />
                        ) : selectedTypeForTable === "unique" ? (
                            <UniqueTable data={groupedData} selectedField={selectedField} />
                        ) : (
                        <SumTable data={groupedData} selectedField={selectedField} />
                    )}
                </div >
            </div >
        </LocalizationProvider >
    );
}

export default Transactions;
