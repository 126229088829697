import React from 'react'
import { useState, useEffect } from 'react'
import "./zap.scss"
import { Box, Container } from '@mui/material'
import zapImage from "../../../assets/images/zap-lightening.png"
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Button, Autocomplete, FormControl, TextField, Typography, CircularProgress } from '@mui/material'
import { SWAP_PRESETS } from '../../../global/zap/zap-contants'
import SwtichImage from "../../../assets/images/switch.png"
import { WaxApi } from '../../../global/api/wax-api'
import Notification from "../../common-components/NotificationPopup/NotificationPopup"
import { supportedSwaps } from './zap-config'
import { ZapService } from '../../../global/zap/zap-service'
import Paper from "@mui/material/Paper";

// Custom component for full-width dropdown menu

// You have an array of configuration

//
// Page Load
//
// All token
// All pairs
//
//
//
//
// Login
// Available tokens and Balance of those tokens
//
//
//
//
// Token Select
// Balance ka polling shuru ho jayega (start or stop)
// Search through all token pairs -> find all matches. Uss hisab we render bottom
//
//
//
//
// Token Change
// Old Poll should be stopped and new polling should begin
//
//
// Pair Chosen (Bottom selected)
// - Poll started for getting price of that pair
// - Compute prices based on data received andd render the view
//
//
// Swap Pe Click Kr skte
//
//
// Set token amount
// - Update new prices
// - Validation less than max balance
function Zap({ loggedInUser, ual }) {
    // Token . json - saved in the file
    const FullWidthPaper = (props) => (
        <Paper {...props} sx={{ width: "30vw", borderRadius: "0" }} />
    );
    const zap = new ZapService(ual)
    const waxApi = new WaxApi();
    const [err, setErr] = useState("")
    const [success, setSuccess] = useState("")
    const [supportedTokens, setSupportedTokens] = useState()
    const [userTokens, setUserTokens] = useState()
    // It should be combined
    const [selectedTokenBalance, setSelectedTokenBalance] = useState(0)
    const [tokenToBeSold, setTokenToBeSold] = useState('Select');
    const [tokenToBeBought, setTokenToBeBought] = useState('Select');
    const [amountToBeSold, setAmountToBeSold] = useState(0)
    const [loadingTokens, setLoadingTokens] = useState(true);
    const [swapResponses, setSwapResponses] = useState({});
    const [selectedPairs, setSelectedPairs] = useState({});
    const [prices, setPrices] = useState({});

    useEffect(() => {
        const fetchSwaps = async () => {
            const allTokens = await waxApi.getAllTokens();
            const newSwapResponses = {};
            for (const key in supportedSwaps) {
                const response = await zap.swapPairs(key);
                newSwapResponses[key] = response;
            }
            setSupportedTokens(allTokens)
            setSwapResponses(newSwapResponses);
            setTokenToBeBought(allTokens[0] ? allTokens[0] : [])
            setLoadingTokens(false)
        };
        fetchSwaps();
    }, []);


    useEffect(() => {
        const getZapData = async () => {
            const ownedCurrency = await waxApi.getWalletBalance(loggedInUser, null)
            setUserTokens(ownedCurrency)
            setTokenToBeSold(ownedCurrency[0])
        }
        getZapData()
    }, [loggedInUser, success])


    const findSelectedPairs = (swapResponses, tokenToBeBought, tokenToBeSold) => {
        const pairs = {};
        Object.keys(swapResponses).forEach((key) => {
            const pair = swapResponses[key].find((pair) => {
                let token1, token2;
                if (key === "alcorSwap") {
                    token1 = pair.pool1.quantity.split(" ")[1];
                    token2 = pair.pool2.quantity.split(" ")[1];
                } else if (key === "defibox") {
                    token1 = pair.reserve0.split(" ")[1];
                    token2 = pair.reserve1.split(" ")[1];
                } else if (key === "tacoSwap") {
                    token1 = pair.pool1.quantity.split(" ")[1];
                    token2 = pair.pool2.quantity.split(" ")[1];
                }
                return (
                    (token1 === tokenToBeBought?.symbol &&
                        token2 === tokenToBeSold?.currency) ||
                    (token2 === tokenToBeBought?.symbol &&
                        token1 === tokenToBeSold?.currency)
                );
            });
            if (pair) {
                pairs[key] = pair;
            }
        });
        return pairs;
    };
    useEffect(() => {
        const fetchSwaps = async () => {
            const allTokens = await waxApi.getAllTokens();
            setSupportedTokens(allTokens)
            setLoadingTokens(false)
        };
        fetchSwaps();
        filterMenu()
    }, [tokenToBeSold])

    useEffect(() => {
        setSelectedTokenBalance(tokenToBeSold?.amount)
        Object.keys(supportedSwaps).forEach((key) => {
            setPrices(prevPrices => ({
                ...prevPrices,
                [key]: 0
            }));
        })
        let pairs = findSelectedPairs(swapResponses, tokenToBeBought, tokenToBeSold);
        setSelectedPairs(pairs);
        const fetchSwaps = async () => {
            const newSwapResponses = {};
            for (const key in supportedSwaps) {
                const filter = pairs?.[key]?.id;
                if (filter) {
                    const response = await zap.swapPairs(key, filter);
                    newSwapResponses[key] = response;
                }
            }
            pairs = newSwapResponses
            const obj = {
            }
            Object.keys(supportedSwaps).forEach((key) => {
                const price = calculateExchangePrice(key, amountToBeSold, pairs)[0];
                obj[key] = price
            })
            setPrices(obj);
            setErr("")
            setSuccess("")
        };
        fetchSwaps();
        const intervalId = setInterval(() => {
            fetchSwaps();
        }, 5000);
        return () => clearInterval(intervalId);
    }, [amountToBeSold, tokenToBeBought, tokenToBeSold]);

    function calculateExchangePrice(exchange, quantityToBeSold, pairs) {
        try {
            const pair = pairs[exchange];
            const { id, pool1, pool2, reserve0, reserve1, token0, token1, fee } = pair;
            let sellingTokenPool, buyingTokenPool;
            if (pool1 && pool2) {
                const isToken1Sold = pool1.contract === tokenToBeSold.contract;
                sellingTokenPool = isToken1Sold ? parseFloat(pool1.quantity) : parseFloat(pool2.quantity);
                buyingTokenPool = isToken1Sold ? parseFloat(pool2.quantity) : parseFloat(pool1.quantity);
            } else if (reserve0 && reserve1) {
                const isToken1Sold = token0.contract === tokenToBeSold.contract;
                const reserveSold = isToken1Sold ? parseFloat(reserve0.split(' ')[0]) : parseFloat(reserve1.split(' ')[0]);
                const reserveBought = isToken1Sold ? parseFloat(reserve1.split(' ')[0]) : parseFloat(reserve0.split(' ')[0]);
                sellingTokenPool = reserveSold;
                buyingTokenPool = reserveBought;
            } else {
                throw new Error(`Unsupported exchange: ${exchange}`);
            }
            // const feeChargedBySwap = zap.computeFee(exchange, exchange === "tacoSwap" ? true : false)
            const price = zap.computeSwapResult(sellingTokenPool, buyingTokenPool, quantityToBeSold, 0.3, 0.1);
            return [price, sellingTokenPool, buyingTokenPool, id];
        } catch (e) {
            console.log(e)
            return [0, 0, 0, 0];
        }
    }

    async function onExchangeClick(exchangeName) {
        const [price, sellingTokenPool, buyingTokenPool, id] = calculateExchangePrice(exchangeName, amountToBeSold, selectedPairs);
        const sellingPool = sellingTokenPool;
        const buyingPool = buyingTokenPool;
        try {
            zap.zapTransaction(exchangeName, sellingPool, buyingPool, tokenToBeSold, tokenToBeBought, amountToBeSold, "", 0.03, id, (e) => {
                if (e === "Success") return setSuccess("Success");
                setErr(e.message);
                console.warn(typeof e.message);
            })?.then((res) => setErr("Success")).catch((e) => console.log(e))
        } catch (e) {
            setErr("An error occured")
            console.log(e)
        }
        setPrices(prevPrices => ({
            ...prevPrices,
            [exchangeName]: price
        }));
    }

    function filterMenu() {
        try {
            const filteredPairs = [];
            for (const exchange in swapResponses) {
                const pair = swapResponses[exchange]
                if (exchange === 'defibox') {
                    const filtered = pair.filter((item) => {
                        return item?.token0?.symbol.includes(tokenToBeSold?.currency) || item?.token1?.symbol.includes(tokenToBeSold?.currency)
                    })
                    filteredPairs.push(filtered)
                }
                else {
                    const filtered = pair.filter((item) => {
                        const pairing = item?.supply.split(' ')[1]
                        return pairing.includes(tokenToBeSold.currency)
                    })
                    filteredPairs.push(filtered)
                }
            }
            const uniqueFilteredArray = [...new Set(filteredPairs)];
            const checkFilter = uniqueFilteredArray.map(arr => {
                return arr.map(obj => {
                    if ((obj.pool1 && obj.pool2) || (obj.reserve0 && obj.reserve1)) {
                        const pool1Token = obj.pool1 ? obj.pool1.quantity.split(' ')[1] : null
                        const pool2Token = obj.pool2 ? obj.pool2.quantity.split(' ')[1] : null
                        const reserve1Token = obj.reserve0 ? obj.reserve0.split(' ')[1] : null
                        const reserve2Token = obj.reserve1 ? obj.reserve1.split(' ')[1] : null
                        let token = null
                        if (reserve1Token && reserve1Token !== tokenToBeSold.currency) {
                            token = reserve1Token
                        }
                        else if (reserve2Token && reserve2Token !== tokenToBeSold.currency) {
                            token = reserve2Token
                        }
                        else if (pool1Token !== tokenToBeSold.currency) {
                            token = pool1Token
                        } else if (pool2Token !== tokenToBeSold.currency) {
                            token = pool2Token
                        }

                        return token ? { "symbol": token } : obj
                    } else {
                        return obj;
                    }
                })
            })
            const mergedFilteredArray = [...checkFilter[0], ...checkFilter[1], ...checkFilter[2]]
            const uniqueMergedFilteredArray = mergedFilteredArray.reduce((acc, current) => {
                const existingItem = acc.find(item => item.symbol === current.symbol);
                if (!existingItem) {
                    acc.push(current);
                }
                return acc;
            }, []);

            const filteredSupportedTokens = supportedTokens.filter(token => {
                return uniqueMergedFilteredArray.some(({ symbol }) => symbol === token.symbol);
            });
            setSupportedTokens(filteredSupportedTokens)
            setTokenToBeBought(filteredSupportedTokens[0])
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Box
            className='main-container'
        >
            <Container className='flex-center-column' style={{ paddingTop: '2rem' }}>
                {err && <Notification type='error' message={err} />}
                {success && <Notification type='success' message={success} />}
                <Typography className='gold-fontmd'>ZAP
                    <span><img src={zapImage} alt="" style={{ width: '1.2rem', paddingLeft: '0.5rem' }} /></span>
                </Typography>
                <Box className="border flex-center-column" maxWidth="md">
                    <div style={{ padding: '2.5rem' }}>
                        <div className='flex-between font-white'>
                            <Typography style={{ marginRight: '3rem' }} >
                                Sell
                            </Typography>
                            <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '3rem' }}>
                                {selectedTokenBalance}
                                <span><AccountBalanceWalletIcon style={{ width: '1rem', verticalAlign: 'middle' }} /></span>
                            </Typography>

                        </div>
                        <div className="zap-text-input font-white">
                            <TextField
                                className="textField"
                                placeholder="Enter a number"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    if (value <= selectedTokenBalance) {
                                        setAmountToBeSold(value);
                                    }
                                    else {
                                        setErr("Enter amount lower than balance")
                                    }
                                }}
                            />
                            <div className="divider"></div>
                            {loadingTokens ? (
                                <CircularProgress />
                            ) : (
                                <FormControl className="formControl zap-text-input" size='large'
                                    sx={{ color: '#fff', width: '80%', background: '#2A0845' }}>
                                    <Autocomplete
                                        sx={{
                                            color: "#fff",
                                            width: "100%",
                                            "& .MuiAutocomplete-inputRoot": {
                                                width: "100%",
                                            },
                                            "& .MuiInputBase-input": {
                                                width: "100%",
                                            },
                                        }}
                                        options={userTokens || []}
                                        getOptionLabel={(option) =>
                                            tokenToBeSold && tokenToBeSold.currency === option.currency
                                                ? option.currency
                                                : `${option.currency} (${option.contract}) - ${option.amount}`
                                        }
                                        value={tokenToBeSold ? tokenToBeSold : null}
                                        onChange={(event, newValue) => {
                                            setTokenToBeSold(newValue);
                                        }}
                                        filterOptions={(options, { inputValue }) =>
                                            options.filter(
                                                (option) =>
                                                    option.currency.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                    option.contract.toLowerCase().includes(inputValue.toLowerCase())
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => {
                                            const { currency, contract, amount } = option;
                                            return (
                                                <li {...props}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span style={{ marginRight: "0.5rem" }}>{currency}</span>
                                                        <span style={{ color: "#aaa", marginRight: "0.5rem" }}>
                                                            {contract}
                                                        </span>
                                                        <span style={{ color: "#6c757d" }}>{amount}</span>
                                                    </div>
                                                </li>
                                            );
                                        }}
                                        PaperComponent={FullWidthPaper} // set the PaperComponent prop to the custom FullWidthPaper component
                                        PaperProps={{
                                            sx: {
                                                borderRadius: "0", // set border radius to 0 to match custom paper component
                                            },
                                        }}
                                    />


                                </FormControl>)}
                        </div>
                        <div className='flex-around arrow-images'>
                            <img src={SwtichImage} style={{ width: '1rem', marginTop: '1rem', marginBottom: '1rem' }} alt="arrow" />
                        </div>
                        <div className='flex-center-column'>
                            <Typography color="white">Buy</Typography>
                            <FormControl
                                className="formControl zap-text-input"
                                size="large"
                                sx={{
                                    color: "#fff",
                                    width: "100%",
                                    background: "#2A0845",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Autocomplete
                                    sx={{
                                        color: "#fff",
                                        width: "100%",
                                        "& .MuiAutocomplete-inputRoot": {
                                            width: "100%",
                                        },
                                        "& .MuiInputBase-input": {
                                            width: "100%",
                                        },
                                    }}
                                    filterOptions={(options, { inputValue }) =>
                                        options.filter((option) => {
                                            const symbolMatch = option.symbol.toLowerCase().includes(inputValue.toLowerCase());
                                            const accountMatch = option.account.toLowerCase().includes(inputValue.toLowerCase());
                                            return symbolMatch || accountMatch;
                                        })
                                    }
                                    options={supportedTokens || []}
                                    getOptionLabel={(option) => option ? `${option.symbol} (${option.account})` : ''}
                                    value={tokenToBeBought ? tokenToBeBought : null}
                                    onChange={(event, newValue) => {
                                        setTokenToBeBought(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Box display="flex" alignItems="center">
                                                <img src={option.logo} alt={option.symbol} width={24} height={24} style={{ marginRight: 8 }} />
                                                <Typography variant="body1">{option.symbol}</Typography>
                                                <Typography variant="body1" style={{ marginLeft: 8, color: '#5a6167' }}>{`${option.account}`}</Typography>

                                            </Box>
                                        </li>
                                    )}
                                />

                            </FormControl>


                            <Typography color="white" style={{ fontSize: '0.8rem' }}>0.1% fee</Typography>
                        </div>
                        <div className='buttons-parent'>
                            {SWAP_PRESETS.map((preset) => {
                                let onClickFunction = null;
                                if (preset.subtitle === 'onAlchorClick') {
                                    onClickFunction = () => onExchangeClick('alcorSwap');
                                } else if (preset.subtitle === 'onTacoClick') {
                                    onClickFunction = () => onExchangeClick('tacoSwap');
                                } else if (preset.subtitle === 'onDefiClick') {
                                    onClickFunction = () => onExchangeClick('defibox');
                                }

                                const isDisabled = prices[preset.exhange] === 0;
                                const disabledClass = isDisabled ? 'button-disabled' : '';
                                return (
                                    <Button
                                        className={`suggestion-button ${disabledClass}`}
                                        onClick={onClickFunction}
                                        disabled={isDisabled}
                                    >
                                        <Typography className="button-title">
                                            {preset.subtitle === 'onAlchorClick' ? prices.alcorSwap ? prices.alcorSwap.toFixed(4) : 0 :
                                                preset.subtitle === 'onDefiClick' ? prices.defibox ? prices.defibox.toFixed(4) : 0 :
                                                    preset.subtitle === 'onTacoClick' ? prices.tacoSwap ? prices.tacoSwap.toFixed(4) : 0 : 0}
                                            {` ${tokenToBeBought?.symbol ? tokenToBeBought?.symbol : 'token'}`}
                                            <div>
                                                {preset.title}
                                            </div>
                                        </Typography>
                                    </Button>
                                );
                            })}

                        </div>

                    </div>
                </Box>
            </Container>
        </Box>
    )
}

export default Zap