export const SMART_CONTRACTS = {
        "nfthive": {
            "wallet" : "nfthivecpu4u",
            "contract": "nfthivecpu4u",
            "price": "fees.0.fees"
        },
}


export const NON_SUBSCRIBER_PRESETS = [
    { stake: 25, days: 1},
    { stake: 50, days: 1},
    { stake: 100, days: 1},
    { stake: 25, days: 3},
    { stake: 50, days: 3},
    { stake: 100, days: 3},
    { stake: 26, days: 7},
    { stake: 50, days: 7},
    { stake: 25, days: 7},
]

export const SUBSCRIBER_PRESETS = [
    { stake: 10},
    { stake: 25},
    { stake: 50},
    { stake: 100},
    { stake: 200},
    { days: 3},
    { days: 6},
    { days: 9},
    { days: 15},
    { days: 30}
]

export const RENTAL_ERRORS = {
    "must transfer positive quantity": "Rental Bill must be atleast 0.01 WAX",
    "overdrawn balance": ""
}

