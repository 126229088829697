import React from "react";


const Disabled = ({ size = 40, parentRef, message }) => {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                fontSize: '1rem',

            }}
        >
            {message}
        </div>
    );
};

export default Disabled;
