export const ACCOUNT_NAME = 'waxptoolsown';

export const CACHE_KEY = {
  SUBSCRIBER: 'SUBSCRIBER',
  WALLET: 'wallet'
};


export const RENTAL_LABEL = "rent"
export const RENEW_LABEL = "renew"
export const ADD_STAKE_LABEL = "more"

export const EXISTING_LABELS = [RENEW_LABEL, ADD_STAKE_LABEL]

export const API_STATUS = {
  NOT_STARTED: '',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const USER_TYPE = {
  ALL_USER: 'ALL_USER',
  NEW_USER: 'NEW_USER',
  EXISTING_USER: 'EXISTING_USER'
}

export const ROUTES = {
  HOME: '/',
  CPU_RENTAL: '/cpu/:wallet',
  ZAP: '/zap',
  ADVANCE_MODE: '/cpu-super',
  PRO_MODE: '/cpu-pro',
  REFERRAL_FORM: '/referral-form',
  REFERRAL: '/referral/:wallet',
  FAQ: '/faq',
  CPU_RENT_HOME: '/rentcpu/:wallet',
  LANDING_PAGE: '/landing-page',
  TRANSACTIONS: '/transactions'
}