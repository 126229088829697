import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "../scss/pro-mode.scss"

export default function CustomerModal(props) {
    const {open, onClose, value, onChange, addUsersToLocal} = props;

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal">
                <div className="flex-between">
                    <Typography variant="h6" color="white">
                        Add Customer
                    </Typography>
                    <Button onClick={onClose} className="close-btn">
                        X
                    </Button>
                </div>
                <hr style={{marginBottom: "2rem"}} />
                <div
                    className="flex-center-column"
                    style={{
                        border: "1px dotted #5E4991",
                        borderSpacing: "2rem",
                        borderRadius: "9px",
                        height: "15rem",
                        overflow: "auto",
                    }}
                >
                    <TextField
                        InputProps={{
                            placeholder:
                                "wallet1,wallet2,wallet3,wallet4....\nor\nwallet1 wallet2 wallet3 wallet4....",
                            disableUnderline: true,
                        }}
                        onChange={onChange}
                        value={value}
                        multiline
                        style={{width: "100%", height: "100%"}}
                        disableHover
                    ></TextField>
                </div>
                <div className="flex-center-column">
                    <Button
                        variant="contained"
                        onClick={() => {
                            addUsersToLocal();
                        }}
                        style={{background: "#5E4991", marginTop: "1rem"}}
                    >
                        Add
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}