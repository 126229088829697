import React from 'react';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography} from '@mui/material';

const SumTable = ({data, selectedField}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6">{selectedField}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6">Sum</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        const key = Object.keys(item)[0];
                        const sumValue = item[key];
                        const account = key.match(/(?<=on account->)[\s\S]+$/)[0] + ` summed as`;
                        const token = key.split('->')[1].replace(`is`, '').trim();
                        return (
                            <TableRow key={index}>
                                <TableCell>{token}</TableCell>
                                <TableCell>{sumValue}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SumTable;
