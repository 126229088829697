import { WaxApi } from "../api/wax-api";
import { transferAction } from "../eosio/actions";
import { Ual } from "../api/ual";
import { supportedSwaps } from "../../components/pages/zap/zap-config";
import { ACCOUNT_NAME } from "../constant";



class ZapService {
    constructor(ual) {
        this.waxApi = new WaxApi()
        console.log("zap service was called")
        this.platformFee = process.env.REACT_APP_ZAP_FEE

        if (ual && ual?.activeUser !== null) {
            this.activeUser = ual.activeUser
            this.walletName = ual.activeUser.accountName
            this.ual = new Ual(ual.activeUser)
        } else {
            Error("User is null")
        }
    }


    // computeSwapResult(sellingTokenPool, buyingTokenPool, quantityToBeSold, feeChargedBySwap) {
    //     let totalQuantity = quantityToBeSold
    //     totalQuantity -= totalQuantity * (1 - this.platformFee)
    //     totalQuantity -= totalQuantity * (1 - feeChargedBySwap)
    //     return (buyingTokenPool - (sellingTokenPool * buyingTokenPool) / (sellingTokenPool + totalQuantity))
    // }
    computeSwapResult(pool1_quantity, pool2_quantity, quantity_swapped, fee_percentage, reduction_percentage) {
        const buyingTokenAmount = (pool2_quantity / (pool1_quantity + quantity_swapped)) * quantity_swapped
        const fee = buyingTokenAmount * fee_percentage / 100
        const result = buyingTokenAmount - fee
        const reduction = result * reduction_percentage / 100
        const finalResult = result - reduction
        return finalResult
    }




    async computeFee(exchange, tacoFlag) {
        if (exchange === "taco" && tacoFlag) {
            return 0.0025
        } else {
            return 0.003
        }
    }

    async swapPairs(exchange, filter = "") {
        try {
            const swap = supportedSwaps[exchange]
            const response = await this.waxApi.contractData(swap?.code, swap?.scope, swap?.table, filter, filter)
            const swapData = response.rows
            return (swapData.length === 1 ? swapData[0] : swapData)
        } catch (err) {
            throw Error("Failed to get swap pairs")
        }
    }

    zapMemo(exchange, currency, quantity, precision, pairId) {
        let memoFormat = supportedSwaps[exchange]?.memo
        const formatted_inputs = {
            "quantity": quantity.toFixed(precision),
            "token": currency?.symbol,
            "contract": currency?.account,
            "pairId": pairId
        }
        for (const key in formatted_inputs) {
            memoFormat = memoFormat.replace(`$${key}`, formatted_inputs[key]);
        }
        return memoFormat
    }

    zapAction(to, quantity, precision, memo, currency) {
        const formattedQuantity = quantity.toFixed(precision) + " " + currency?.currency
        return transferAction(this.walletName, to, formattedQuantity, memo)
    }

    zapTransaction(exchange, sellingTokenPool, buyingTokenPool, sellingCurrency, buyingCurrency, quantityTobeSold, latestPrice, feeCharged, pairId, notification) {
        const sellingTokenPoolDecimal = sellingTokenPool?.toString().split('.')[1].length
        console.log(sellingCurrency, 'zmzm')
        const buyingTokenPoolDecimal = buyingTokenPool?.toString().split('.')[1].length
        const quantityToBeBought = this.computeSwapResult(sellingTokenPool, buyingTokenPool, quantityTobeSold, 0.3, 0.1)
        //TODO - handle pair id
        const memo = this.zapMemo(exchange, buyingCurrency, quantityToBeBought, buyingTokenPoolDecimal, pairId)
        // const effectiveSoldQuanity = quantityTobeSold * (1 - this.platformFee)
        const swapAction = this.zapAction(supportedSwaps[exchange]?.contract_address, quantityTobeSold, sellingCurrency.decimals ? sellingCurrency.decimals : sellingTokenPoolDecimal, memo, sellingCurrency)
        const feeAction = this.zapAction(ACCOUNT_NAME, quantityTobeSold * this.platformFee, sellingCurrency.decimals ? sellingCurrency.decimals : sellingTokenPoolDecimal, "Fee ZAP", sellingCurrency)
        console.log(swapAction, feeAction, sellingTokenPool, buyingTokenPool, 'actions')
        this.ual.performAction([swapAction, feeAction]).then(succ => {
            notification("Success")
        }).catch(e => {
            notification(e)
            console.log(e)
        })

    }


}

export { ZapService }