import { useState, useEffect } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

function Notification(props) {
    const { type, message } = props;
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <SnackbarContent
                message={message}
                sx={{
                    backgroundColor:
                        type === 'error'
                            ? (theme) => theme.palette.error.main
                            : type === 'success'
                                ? (theme) => theme.palette.success.main
                                : (theme) => theme.palette.info.main,
                    color: 'white'
                }}
            />
        </Snackbar>
    );
}

export default Notification;
