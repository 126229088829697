import React from "react";
import { CircularProgress } from "@mui/material";

const Loader = ({ size = 40, parentRef }) => {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
            }}
        >
            <CircularProgress size={size} style={{ color: "#fff" }} />
        </div>
    );
};

export default Loader;
