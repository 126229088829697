import {post, get} from "./http";
import axios from "axios";

class WaxApi {
    constructor() {
        this.waxBalanceUrl = `https://lightapi.eosamsterdam.net/api/balances/wax`
        this.waxToUSDRateURL = 'https://www.api.bloks.io/wax/ticker/%5Bobject%20Object%5D';
        this.waxNodes = process.env.REACT_APP_WAX_NODES.split(",")
        this.tablesEndpoint = "/v1/chain/get_table_rows"
        this.accountResourcesEndpoint = '/v1/chain/get_account'
        this.zapNode = 'https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/tokens.json'
        this.waxHistoryEnpoints = process.env.REACT_APP_HISTORY_NODES.split(",")
    }


    async retryEndpoints(payload, extension = this.tablesEndpoint) {
        let index = 0;
        while (index < this.waxNodes.length) {
            try {
                let response = await axios.post(this.waxNodes[index] + extension, payload);
                return response.data;
            } catch (error) {
                index++;
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        throw new Error("All endpoints failed");
    }

    async tryActionsEnpoints(url) {
        const endpoints = this.waxNodes;
        for (const endpoint of this.waxHistoryEnpoints) {
            try {
                const response = await axios.get(`${endpoint}/${url}`);
                return response;
            } catch (error) {
                console.error(`Failed to get data from ${endpoint}: ${error.message}`);
            }
        }
        throw new Error(`Failed to get data from all endpoints: ${endpoints.join(', ')}`);
    }


    async contractData(code, scope, table, upperBound = "", lowerBound = "", index_position = 1, limit = -1) {
        const params = {
            "json": true,
            "code": code,
            "scope": scope,
            "table": table,
            "lower_bound": lowerBound,
            "upper_bound": upperBound,
            "index_position": index_position,
            "key_type": "i64",
            "limit": limit,
            "reverse": false,
            "show_payer": false
        }
        return this.retryEndpoints(params);
    }

    async getAccountResources(account_name) {
        return this.retryEndpoints({account_name}, this.accountResourcesEndpoint);
    }

    async getWalletBalance(wallet, tokenToBeFetched = "WAX") {
        try {
            const balanceRes = await get(`${this.waxBalanceUrl}/${wallet}`);
            if (tokenToBeFetched === null) {
                return balanceRes?.balances
            } else {
                const balance = balanceRes?.balances?.find(datum => datum.currency === tokenToBeFetched)
                return balance?.amount?.split(".")[0] || 0
            }
        } catch (e) {
            throw e
        }
    }

    async getWAXToUSDRate() {
        return get(this.waxToUSDRateURL);
    }

    async getAllTokens() {
        try {
            const response = await axios.get(this.zapNode)
            return response.data
        } catch (error) {
            console.log(error);
        }
    }

    async getActionHistory(account, action = '', contract = '', initiator = '', skip = 0, before = '', after = '', from = '', to = '') {
        const filters = [];
        if (['send', 'receive'].includes(action)) {
            filters.push(`transfer.${action === 'send' ? `from` : `to`}=${account}`);
        } else if (action) {
            filters.push(`act.name=${action}`);
        }
        if (contract) filters.push(`act.account=${contract}`);
        if (initiator) filters.push(`act.authorization.actor=${encodeURIComponent(initiator)}`);
        if (from) filters.push(`transfer.from=${from}`);
        if (to) filters.push(`transfer.to=${to}`);
        const query = `v2/history/get_actions?account=${account}&noBinary=true&limit=1000&simple=true&skip=${skip}&${filters.join('&')}${before ? `&before=${encodeURIComponent(before.toISOString())}` : ''}${after ? `&after=${encodeURIComponent(after.toISOString())}` : ''}`;

        try {
            return (await this.tryActionsEnpoints(query)).data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async getActionHistoryWithPost(account, pos = '-1', offset = '-50') {
        const payload = {
            "account_name": account,
            "pos": pos,
            "offset": offset,
            "sort": "desc",
        }
        try {
            const response = await axios.get(`https://wax.greymass.com/v1/history/get_actions?account_name=${account}&pos=${pos}&offset=${offset}`);
            return response.data.actions;
        } catch (error) {
            console.log(error);
        }
    }

    async fetchWufToWaxRate() {
        try {
            const response = await axios.get('https://wax.alcor.exchange/api/v2/swap/pools/1667');
            const priceB = response.data.priceB;
            const adjustedPrice = priceB;
            return adjustedPrice;
        } catch (error) {
            throw new Error("Failed to fetch WUF to WAX conversion rate");
        }
    }

}

export {WaxApi};