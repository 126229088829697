import React from 'react';
import {LinearProgress, Typography} from '@mui/material';

const LinearProgressLoader = ({loading, progress, title}) => {
    return (
        loading && (
            <div style={{marginBottom: '16px'}}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <LinearProgress variant="determinate" value={progress} />
            </div>
        )
    );
};

export default LinearProgressLoader;