import React from "react";
import {Box, Typography} from "@mui/material";
import "./Footer.scss"
import CopyrightImage from "../../../assets/images/copyright1.png";
// import Socials from "../../../assets/images/socials.png";
import DiscordIcon from "../../../assets/images/DiscordIcon.png"
import TwitterIcon from "../../../assets/images/TwitterIcon.png"

const Footer = () => {
    return (
        <Box
            className="hide"
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                height: "2.5rem",
                width: "100%",
                background: "var(--primary)",
                color: "white",
                paddingX: "2rem",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                zIndex: 1,
            }}
        >
            <Box sx={{display: "flex", alignItems: "center", marginLeft: "2rem"}}>
                <a
                    href="https://discord.gg/pnQaaMaps3"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={DiscordIcon}
                        alt="Discord"
                        style={{
                            width: "1.5rem",
                            height: "1.3rem",
                            marginRight: "1rem",
                        }}
                    />
                </a>
                <a
                    href="https://twitter.com/WaxpTools"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={TwitterIcon}
                        alt="Twitter"
                        style={{
                            width: "1.5rem",
                            height: "1.3rem",
                            marginRight: "1rem",
                        }}
                    />
                </a>
            </Box>
            <span style={{color: "#b9b5b8", marginRight: '2rem'}}>
                <img
                    src={CopyrightImage}
                    style={{
                        width: "10rem",
                        display: "inline",
                        marginRight: "0.5rem" /* add some spacing */,
                    }}
                    alt=""
                />
            </span>
        </Box>

    );
};

export default Footer;