import React, {useState, useEffect} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    TextField,
    InputAdornment,
    Tooltip,
    IconButton,
    Button,
    Typography,
    useMediaQuery,
    Checkbox
} from "@mui/material";
import "./scss/table.scss"
import {Delete} from "@mui/icons-material";
import {ADD_STAKE_LABEL, RENEW_LABEL, RENTAL_LABEL, USER_TYPE} from '../../../global/constant';
import {calculateNumberOfDays} from '../../../global/helper';
import {Rental} from "../../../global/cpu_rental/rental";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {descendingComparator, getComparator, stableSort} from "../../../global/helpers/pro-table.helpers";


// Table column configuration
const columns = [
    {id: "Accounted", label: "Wallets"},
    {id: "Status", label: "Status", hideInMobile: true},
    {id: "Stake", label: "Rent"},
    {id: "Actions", label: "Actions"},
];

// Custom table head component for sorting
function EnhancedTableHead({columns, onRequestSort, order, orderBy, onSelectAll, onDeleteSelected}) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAllClick = (event) => {
        setSelectAll(event.target.checked);
        onSelectAll(event.target.checked)
        // allUsers.forEach((user) => (user.checked = event.target.checked));
    };
    const handleDeleteSelected = (event) => {
        onDeleteSelected(event)
    }

    return (
        <TableHead style={{background: '#230838', height: '4rem'}}>
            <TableRow style={{borderBottom: '#5e4991 solid 1px'}}>
                {columns.map((column) => {
                    if (isMobile && (column.id === "Check" || column.id === "Status")) {
                        return null;
                    }
                    if (column.id === "Accounted") {
                        return (
                            <TableCell
                                className="table-cell-head"
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                            >
                                <Tooltip title="Check All" style={{paddingRight: 0}}>
                                    <IconButton aria-label="check-all">
                                        <Checkbox className="check hide-on-sm" style={{position: 'relative', left: '-10px', paddingRight: 0}} onChange={handleSelectAllClick} />
                                    </IconButton>
                                </Tooltip>
                                {column.label}
                            </TableCell>
                        );
                    }
                    if (column.id === "Actions") {
                        return (
                            <TableCell
                                className="table-cell-head"
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                            >
                                { }

                            </TableCell>
                        );
                    }
                    return (
                        <TableCell
                            className="table-cell-head"
                            key={column.id}
                            sortDirection={orderBy === column.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : "asc"}
                                onClick={createSortHandler(column.id)}
                                className="table-cell-head"
                            >
                                {column.label}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}



export default function MyTable({
    newUsers,
    existingUsers,
    searchFieldResult,
    rentalPrice,
    vipDiscount,
    userFilter,
    handleRental,
    deleteFromCache,
    loggedInUser,
    bulkRent,
    bulkRentForNewUser,
    totalCharges,
    login
}) {
    const [subscriber, setSubscriber] = useState(null);
    const [extraDays, setExtraDays] = useState({})
    const [extraStake, setExtraStake] = useState({})
    const [readOnlySubscriber, setReadOnlySubscriber] = useState({})
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(1);
    const [jumpToPage, setJumpToPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const totalChargesForNewUser = (stakeAdded, days, type) => {
        let totalCost = 0
        const newUsersData = allUser.filter((user) => {
            return user.checked === true && user.stake === 0
        })
        newUsersData.forEach(subscriber => {
            if (stakeAdded > 0 && days > 0) {
                totalCost += Rental.rentalCharges(stakeAdded, days, rentalPrice, false, getVipDiscount())
            }
        })
        return totalCost
    }


    const getVipDiscount = () => {
        if (loggedInUser === "yedsa.wam") {
            return 80
        } else if (loggedInUser === ".an1..c.wam") {
            return 94
        } else if (loggedInUser === 'nzoeg.wam') {
            return 80
        } else if (loggedInUser === 'waxptoolsadm') {
            return 80
        } else {
            return 100
        }
    }

    const transformValue = (inputStake, inputDays, label, subscriber) => {
        let stake = Number(inputStake);
        let days = Number(inputDays);
        let rentalLabel = RENTAL_LABEL
        if (label !== RENTAL_LABEL) {
            const existingUser = existingUsers.find(user => user?.account === subscriber.account)
            if (readOnlySubscriber[subscriber?.account] === RENEW_LABEL) {
                stake = Number(stake - existingUser.stake)
                rentalLabel = ADD_STAKE_LABEL
            } else {
                const existingDay = calculateNumberOfDays(new Date().getTime(), existingUser?.renewal);
                days = Math.ceil(days - Math.round(existingDay))
                rentalLabel = RENEW_LABEL
            }
        }
        return {stake, days, rentalLabel};
    }

    existingUsers.forEach((user) => {
        user.checked = false;
        user.disabledStakeField = false;
        user.disabledDaysField = false;
    });
    newUsers.forEach((user) => {
        user.checked = false;
        user.disabledStakeField = false;
        user.disabledDaysField = false;
    });
    const allUsers = [...newUsers, ...existingUsers];
    const searchUser = searchFieldResult
    const [allUser, setAllUser] = useState(allUsers)
    const [presetStake, setPresetStake] = useState(50)
    const [presetDays, setPresetDays] = useState(3)
    const [renderTotalCharges, setRenderTotalChargers] = useState(false)
    const [newUserRender, setNewUserRender] = useState(newUsers)
    const [existingUserRender, setExistingUserRender] = useState(existingUsers)

    useEffect(() => {
        setAllUser([...newUsers, ...existingUsers])
        setNewUserRender(newUsers)
        setExistingUserRender(existingUsers)
    }, [newUsers.length, existingUsers.length])

    useEffect(() => {
        const isChecked = allUser.filter((user) => {
            return user.checked === true && user.stake == 0
        })
        const isNewUserChecked = newUserRender.filter((user) => {
            return user.checked === true
        })
        if (isNewUserChecked.length !== 0) {
            setRenderTotalChargers(true)
        }
        if (isChecked.length === 0 && isNewUserChecked.length === 0) {
            setRenderTotalChargers(false)
        }
        else if (isChecked.length !== 0) {
            setRenderTotalChargers(true)
        }
    }, [allUser, newUserRender])

    const handleDataFromChild = (data) => {
        if (userFilter === USER_TYPE.EXISTING_USER) {
            const updatedExistingUsers = existingUsers.map(user => ({...user, checked: data}));
            setExistingUserRender(updatedExistingUsers)
        } else if (userFilter === USER_TYPE.NEW_USER) {
            const updatedNewUsers = newUsers.map(user => ({...user, checked: data}));
            setNewUserRender(updatedNewUsers)
        } else {
            const updatedUsers = allUser.map(user => ({...user, checked: data}));
            setAllUser(updatedUsers);
        }
    };

    const handleDeleteSelectedFromChild = (data) => {
        const deleteSelected = allUser.filter((user) => {
            return user.checked === true
        })
        const deleteSelectedFromCache = allUser.filter((user) => {
            return user.checked === false
        })
        for (let i = 0; i < deleteSelected.length; i++) {
            deleteFromCache([deleteSelected[i].account])
        }
        setAllUser(deleteSelectedFromCache)
    }

    const handleUserCheck = (user) => {
        const updatedUser = {...user, checked: !user.checked};
        const updatedUsers = allUser.map((u) =>
            u.account === user.account ? updatedUser : u
        );
        const updatedExistingUsers = existingUsers.map((u) =>
            u.account === user.account ? updatedUser : u
        );
        const updatedNewUsers = newUsers.map((u) =>
            u.account === user.account ? updatedUser : u
        );
        newUsers = updatedNewUsers
        existingUsers = updatedExistingUsers
        console.log(newUsers, existingUsers, 'updatedUsers')
        setAllUser(updatedUsers);
        setNewUserRender(updatedNewUsers)
        setExistingUserRender(updatedExistingUsers)
    };


    const [copyExtraDays, setCopyExtraDays] = useState({})
    const [copyExtraStake, setCopyExtraStake] = useState({extraStake})

    const handleValueChange = (event, user) => {
        const newValue = event.target.value;
        const updatedUser = {...user, disabledDaysField: newValue > 0};
        const updatedUsers = allUser.map((u) =>
            u.account === user.account ? updatedUser : u
        );
        setAllUser(updatedUsers)
    };
    const handleDaysChange = (event, user) => {
        const newValue = event.target.value;
        const updatedUser = {...user, disabledStakeField: newValue > 0};
        const updatedUsers = allUser.map((u) =>
            u.account === user.account ? updatedUser : u
        );
        setAllUser(updatedUsers)
    };
    // Table Rows
    const rows = (searchFieldResult.length === allUser.length ? (userFilter === USER_TYPE.NEW_USER ? newUserRender :
        userFilter === USER_TYPE.EXISTING_USER ? existingUserRender :
            allUser) : searchUser).map((user) => {
                // allUser.length === 0 ? allUsers : allUser) : searchUser).map((user) => {
                const {
                    stake,
                    days,
                    rentalLabel
                } = transformValue(Number(extraStake[user.account]), Math.round(Number(extraDays[user.account])), existingUsers.some(obj => obj.account === user.account) === true ? null : RENTAL_LABEL, user)
                return (
                    {
                        Accounted: <div className={existingUsers.some(obj => obj.account === user.account) ? 'true-dot' : 'dot'} >
                            <Checkbox className="check hide-on-sm" onChange={() => {handleUserCheck(user)}} checked={user.checked} />
                            <span style={{paddingLeft: '3px'}}>{user.account}</span></div >,
                        Status: existingUsers.some(obj => obj.account === user.account) === true ?
                            (<span className="subscriber">Existing</span>) : (<span className="non-subscriber">New</span>),
                        Stake: existingUsers.some(obj => obj.account === user.account) === true ?

                            (<div className="flex-column-sm"><div className="">
                                <div className="flex-between flex-column-sm">
                                    <TextField
                                        variant="standard"
                                        className="table-input"
                                        key={`${user.account}existingstake`}
                                        defaultValue={50}
                                        value={extraStake[user.account]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography style={{color: '#4c4c6a'}}>{copyExtraStake[user.account]}+</Typography>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography style={{color: '#4c4c6a'}}>Wax</Typography>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(event) => {
                                            const readOnlySubscriberData = {
                                                ...readOnlySubscriber,
                                                [user.account]: RENEW_LABEL
                                            };
                                            const extraStakeData = {
                                                ...extraStake,
                                                [user.account]: event.target.value
                                            };
                                            setExtraStake({...extraStakeData});
                                            setReadOnlySubscriber({...readOnlySubscriberData});
                                            handleValueChange(event, user)
                                        }}
                                        disabled={user.disabledStakeField || copyExtraDays[user.account] < 0}
                                    /><Button className="pay-buttons height-sm"
                                        disabled={user.disabledStakeField}
                                        onClick={() => {
                                            // handleOpen(datum);
                                            handleRental(user.account, extraStake[user.account], (user.renewal - new Date()) / 86400000, rentalLabel)
                                            console.log()
                                        }}>{Rental.rentalCharges(extraStake[user.account], (user.renewal - new Date()) / 86400000, rentalPrice, false, getVipDiscount()).toFixed(3) + " WAX"}</Button></div></div>
                                <div className="l">
                                    <div className="flex-between flex-column-sm">
                                        <TextField className="table-input "
                                            variant="standard"
                                            key={`${user.account}existingDays`}
                                            defaultValue={3}
                                            value={extraDays[user.account]}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography style={{color: '#4c4c6a'}}>{copyExtraDays[user.account]}+</Typography>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end" disablePointerEvents>
                                                        <Typography style={{color: '#4c4c6a'}}>Days</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(event) => {
                                                const readOnlySubscriberData = {
                                                    ...readOnlySubscriber,
                                                    [user.account]: ADD_STAKE_LABEL
                                                };
                                                const extraDaysData = {
                                                    ...extraDays,
                                                    [user.account]: event.target.value
                                                };
                                                setReadOnlySubscriber({...readOnlySubscriberData});
                                                setExtraDays({...extraDaysData});
                                                handleDaysChange(event, user)
                                            }}
                                            disabled={user.disabledDaysField}
                                        /><Button className="pay-buttons height-sm"
                                            disabled={user.disabledDaysField}
                                            onClick={() => {
                                                // handleOpen(datum);
                                                handleRental(user.account, copyExtraStake[user.account], extraDays[user.account], rentalLabel)
                                            }}>{Rental.rentalCharges(copyExtraStake[user.account], extraDays[user.account], rentalPrice, true, getVipDiscount()).toFixed(3) + " WAX"}</Button></div></div>
                            </div>) :
                            (<div className="flex-input">
                                <TextField className="table-input"
                                    variant="standard"
                                    key={user.account}
                                    defaultValue={50}
                                    value={extraStake[user.account]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography style={{color: '#4c4c6a'}}>Wax</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        const readOnlySubscriberData = {
                                            ...readOnlySubscriber,
                                            [user.account]: RENEW_LABEL
                                        };
                                        const extraStakeData = {
                                            ...extraStake,
                                            [user.account]: event.target.value
                                        };
                                        setExtraStake({...extraStakeData});
                                        setReadOnlySubscriber({...readOnlySubscriberData});
                                    }}
                                    onBlur={(event) => {
                                        const readOnlySubscriberData = {
                                            ...readOnlySubscriber,
                                            [user.account]: RENEW_LABEL
                                        };
                                        const extraStakeData = {
                                            ...extraStake,
                                            [user.account]: event.target.value
                                        };
                                        setExtraStake({...extraStakeData});
                                        setReadOnlySubscriber({...readOnlySubscriberData});
                                    }}
                                />
                                <TextField className="table-input"
                                    variant="standard"
                                    key={`${user.account}1`}
                                    defaultValue={3}
                                    value={extraDays[user.account]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography style={{color: '#4c4c6a'}}>Days</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        const readOnlySubscriberData = {
                                            ...readOnlySubscriber,
                                            [user.account]: ADD_STAKE_LABEL
                                        };
                                        const extraDaysData = {
                                            ...extraDays,
                                            [user.account]: event.target.value
                                        };
                                        setReadOnlySubscriber({...readOnlySubscriberData});
                                        setExtraDays({...extraDaysData});
                                    }}
                                    onBlur={(event) => {
                                        const readOnlySubscriberData = {
                                            ...readOnlySubscriber,
                                            [user.account]: ADD_STAKE_LABEL
                                        };
                                        const extraDaysData = {
                                            ...extraDays,
                                            [user.account]: event.target.value
                                        };
                                        setReadOnlySubscriber({...readOnlySubscriberData});
                                        setExtraDays({...extraDaysData});
                                    }}
                                />
                                <div className="flex-try" style={{width: '60% !important'}}>
                                    <Button className="pay-buttons table-pay width-full" onClick={() => {
                                        handleRental(user.account, stake, days, rentalLabel)
                                    }}>{Rental.rentalCharges(extraStake[user.account], extraDays[user.account], rentalPrice, false, getVipDiscount()).toFixed(3) + " WAX"}</Button>
                                </div>
                            </div>),
                        Actions: existingUsers.some(obj => obj.account === user.account) === true ? (<div className="existing-delete">
                            <Delete style={{color: 'red'}} onClick={() => {
                                deleteFromCache([user.account])
                            }} />
                        </div>) : (<div className="flex-try">
                            <Delete style={{color: 'red'}} onClick={() => {
                                deleteFromCache([user.account])
                            }} />
                        </div>)
                    })
            });

    //pagination
    const handleJumpToPage = () => {
        setPage(jumpToPage);
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // Get the current page of data
    const getSortedData = () => {
        const sortedData = stableSort(rows, getComparator(order, orderBy));
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = parseInt(startIndex) + parseInt(rowsPerPage);
        return sortedData.slice(startIndex, endIndex);
    };

    useEffect(() => {
        let extraDaysData = {};
        let extraStakesData = {};
        let presetStakeData = {}
        let presetDaysData = {}
        newUsers.forEach((datum) => {
            extraDaysData = {
                ...extraDaysData,
                [datum.account]: 3
            };
            extraStakesData = {
                ...extraStakesData,
                [datum.account]: 50
            };
            presetDaysData = {
                ...presetDaysData,
                [datum.account]: 3
            };
            presetStakeData = {
                ...presetStakeData,
                [datum.account]: 50
            };
        });
        existingUsers.forEach((datum) => {
            extraDaysData = {
                ...extraDaysData,
                [datum.account]: datum.renewal ? calculateNumberOfDays(new Date().getTime(), datum.renewal) : 0
            };
            extraStakesData = {
                ...extraStakesData,
                [datum.account]: datum.stake ? Number(datum.stake.toFixed(2)) : 0
            };

            presetDaysData = {
                ...presetDaysData,
                [datum.account]: 0
            };
            presetStakeData = {
                ...presetStakeData,
                [datum.account]: 0
            };

        });
        setExtraDays({...presetDaysData});
        setExtraStake({...presetStakeData});
        setCopyExtraStake({...extraStakesData})
        setCopyExtraDays({...extraDaysData})
    }, [newUsers.length, existingUsers.length])

    function handlebulkRent(newPresetStake, newPresetDays, RENTAL_LABEL) {
        if (!loggedInUser) {
            login();
            return;
        }
        const newUsersData = allUser.filter((user) => {
            return user.checked === true && user.stake === 0
        })
        bulkRentForNewUser(newPresetStake, newPresetDays, RENTAL_LABEL, newUsersData);
    }
    return (
        <>
            <TableContainer component={Paper} style={{border: '1px solid #5E4991', overflowX: 'hidden'}}>
                <Table>
                    <EnhancedTableHead
                        columns={columns}
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        onSelectAll={handleDataFromChild}
                        onDeleteSelected={handleDeleteSelectedFromChild}

                    />
                    <TableBody>
                        {getSortedData().map((row) => (
                            <TableRow key={row.name}>
                                {columns.map((column) => {
                                    return (
                                        <TableCell
                                            className={column.hideInMobile ? 'hide-in-mobile' : 'table-cell'}
                                            style={{borderBottom: '1px solid #5E4991'}}
                                            key={column.id}>{row[column.id]}</TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {renderTotalCharges ? (<div className="flex-between total-box border-bottom" >
                    <Typography color="White" className="font-on-sm">Add</Typography>
                    <TextField className="bulk-input" id="standard-basic" variant="standard" value={presetStake} onChange={(e) => {
                        setPresetStake(e.target.value)
                    }} />
                    <Typography color="White" className="font-on-sm">Wax</Typography>
                    <TextField className="bulk-input" id="standard-basic" variant="standard" value={presetDays} onChange={(e) => {
                        setPresetDays(e.target.value)
                    }} />
                    <Typography color="White" className="font-on-sm">Day</Typography>
                    <Button className="pay-buttons table-pay" onClick={() => handlebulkRent(presetStake, presetDays, RENTAL_LABEL)}>{totalChargesForNewUser(presetStake, presetDays, RENTAL_LABEL).toFixed(3) + " WAX"}</Button>
                    <Tooltip title="Delete Selected" style={{justifySelf: 'end'}}>
                        <IconButton aria-label="delete-selected">

                            <DeleteSweepIcon onClick={handleDeleteSelectedFromChild} style={{verticalAlign: 'middle', alignSelf: 'end', color: 'red'}} />
                        </IconButton>
                    </Tooltip>
                </div>) : null}
                <div className="flex-between pagination-box" >
                    <div className="d-flex hide-on-lg">
                        <TextField
                            variant="standard"
                            value={rowsPerPage}
                            defaultValue={rowsPerPage}
                            onChange={(event) => {
                                setRowsPerPage(event.target.value);
                            }}
                            size='small'
                            className="hide-on-sm"
                            style={{width: '50px'}}
                        />
                        <Typography style={{color: '#969696', fontSize: '0.8rem', paddingRight: '0.2rem'}}>/Page</Typography>
                    </div>
                    <div style={{width: '100%', display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{paddingRight: '0.5rem'}}>
                            <Button className="pagination-buttons" onClick={(event) => {
                                if (page > 1) {
                                    setPage(parseInt(parseInt(page) - 1))
                                    setJumpToPage(parseInt(parseInt(page) - 1))
                                }
                            }}><Typography style={{color: '#d3d6d5', fontSize: '0.8rem'}}>Back</Typography></Button>
                        </div>
                        <div className="d-flex" >
                            <TextField
                                variant="standard"
                                value={rowsPerPage}
                                defaultValue={rowsPerPage}
                                onChange={(event) => {
                                    setRowsPerPage(event.target.value);
                                }}
                                size='small'
                                className=""
                                style={{width: '40px'}}
                            />
                            <Typography style={{color: '#969696', fontSize: '0.8rem'}} className="">/Page</Typography>
                        </div>
                        <div className="d-flex hide-on-sm">
                            <Typography style={{color: '#969696', fontSize: '0.8rem', paddingRight: '5px'}}>Page</Typography>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    size="small"
                                    className="table-input-border"
                                    placeholder={page}
                                    type="number"
                                    value={jumpToPage}
                                    onChange={(e) => {
                                        if (0 < e.target.value <= Math.ceil(rows.length / rowsPerPage) - 1) {
                                            setJumpToPage(parseInt(e.target.value))
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: {min: 1, max: Math.ceil(rows.length / rowsPerPage)},
                                    }}
                                    style={{marginRight: '1rem'}}
                                    onBlur={handleJumpToPage}
                                />
                                <Typography color="white">of {Math.ceil(rows.length / rowsPerPage)}</Typography>
                            </div>
                        </div>
                        <div style={{paddingLeft: '0.5rem'}}>
                            <Button className="pagination-buttons" onClick={(event) => {
                                if (page <= Math.ceil(rows.length / rowsPerPage) - 1) {
                                    setPage(parseInt(parseInt(page) + 1))
                                    setJumpToPage(parseInt(parseInt(page) + 1))
                                }
                            }}><Typography style={{color: '#d3d6d5', fontSize: '0.8rem'}}>Next</Typography></Button>
                        </div>
                    </div>
                </div>

            </TableContainer>
        </>
    );
}