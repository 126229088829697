import React, {useState} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './menu.scss';
import {useNavigate, useLocation} from 'react-router-dom';
import {ROUTES} from '../../../global/constant';
import {useRentCpuHandler} from '../../../global/helper';

function Menus({ual, loggedInUser, activeItem, onItemClick, onClose}) {
    const onRentCpuClick = useRentCpuHandler(loggedInUser)
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box className='menu-container'>
            <IconButton className='close-button' onClick={onClose}>
                <CloseIcon className="w-10 h-10 text-gray-400" />
            </IconButton>
            <Box className='menu-items'>
                <Typography
                    className={`menu-item ${location.pathname == ROUTES.TRANSACTIONS ? 'active' : ''}`}
                    onClick={() => {
                        onItemClick('TraceMyWax')
                        navigate(ROUTES.TRANSACTIONS);
                    }}
                >
                    <span className='font-sans font-bold text-2xl'>Trace MyWAX</span>
                </Typography>
                <Typography
                    className={`menu-item ${location.pathname.includes("/cpu/") ? 'active' : ''}`}
                    onClick={(e) => {
                        onItemClick('RentCpu')
                        onRentCpuClick(e)
                    }}
                >
                    <span className='font-sans font-bold text-2xl'>Rent CPU</span>
                </Typography>
            </Box>
        </Box>
    );
}

export default Menus;