import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({title, children}) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} aria-controls={`${title}-content`} id={`${title}-header`}>
                <Typography variant="h6">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <hr style={{borderColor: '#5E4991', marginBottom: '1rem'}} />
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;