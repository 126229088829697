import {Ual} from "../api/ual";

function transferAction(from, to, quantity, memo, contract = null) {
    const data = {
        from,
        to,
        quantity,
        memo
    }
    return Ual.createAction("transfer", contract !== null ? contract : "eosio.token", from, data)
}


export {transferAction}