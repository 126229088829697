import React, {useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {ADD_STAKE_LABEL, EXISTING_LABELS, RENEW_LABEL, RENTAL_LABEL, USER_TYPE, API_STATUS, CACHE_KEY} from '../../../global/constant';

const BulkActions = ({setErrorMessage, bulkRent, totalCharges}) => {
    const [newPresetStake, setNewPresetStake] = useState("");
    const [newPresetDays, setNewPresetDays] = useState("");
    const [existingStake, setExistingStake] = useState("");
    const [existingDay, setExistingDay] = useState("");

    return (
        <div className='flex-center-column width-full'>
            <div className='bulk-heading'>Bulk Actions</div>
            <Box className='soft-border font-white width-full padding-zero-sm' p={2}>
                <div className='d-flex '>
                    <div className='font-white'>Add</div>
                    <TextField
                        id="newPresetStake"
                        variant="standard"
                        className='bulk-input'
                        value={newPresetStake}
                        onChange={(e) => setNewPresetStake(Number(e.target.value))}
                    />
                    <div className='font-white'>Wax for </div>
                    <TextField
                        id="newPresetDays"
                        variant="standard"
                        className='font-white bulk-input'
                        value={newPresetDays}
                        onChange={(e) => setNewPresetDays(Number(e.target.value))}
                    />
                    <div className='font-white'>Days (All New Accounts)</div>
                    <Button
                        className='pay-buttons'
                        onClick={() => {
                            if (!newPresetDays || !newPresetStake) {
                                setErrorMessage("Please enter a valid number");
                                return;
                            }
                            bulkRent(newPresetStake, newPresetDays, RENTAL_LABEL)
                        }
                        }
                    >
                        {totalCharges(newPresetStake, newPresetDays, RENTAL_LABEL).toFixed(3) + " WAX"}
                    </Button>
                </div>
                {/* existing user */}
                <div className='d-flex '>
                    <div className='font-white'>Add </div>
                    <TextField
                        id="existingStake"
                        variant="standard"
                        className='bulk-input'
                        value={existingStake}
                        onChange={(e) => setExistingStake(Number(e.target.value))}
                    />
                    <div className='font-white'> Wax ( All Rented Accounts )</div>
                    <Button
                        className='pay-buttons'
                        onClick={() => bulkRent(existingStake, null, ADD_STAKE_LABEL)}
                    >
                        {totalCharges(existingStake, null, ADD_STAKE_LABEL).toFixed(3) + " WAX"}
                    </Button>
                </div>
                <div className='d-flex '>
                    <div className='font-white'>Add </div>
                    <TextField
                        id="existingDay"
                        variant="standard"
                        className='font-white bulk-input'
                        value={existingDay}
                        onChange={(e) => setExistingDay(Number(e.target.value))}
                    />
                    <div className='font-white'> Days (All Rented Accounts)</div>
                    <Button
                        className='pay-buttons'
                        onClick={() => {
                            if (existingDay % 3 !== 0) {
                                setErrorMessage("Please enter a multiple of 3");
                                setExistingDay(existingDay + 3 - (existingDay % 3));
                                return;
                            }
                            bulkRent(null, existingDay, RENEW_LABEL)
                        }}
                    >
                        {totalCharges(null, existingDay, RENEW_LABEL).toFixed(3) + " WAX"}
                    </Button>
                </div>
            </Box>
        </div>
    );
};

export default BulkActions;
