import React, {useState} from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import AdvanceMode from '../components/pages/advance-mode/advance-mode';
import {ROUTES} from '../global/constant';
import "./router.scss"
import Box from "@mui/material/Box";
import Redirect from './redirect';
import CpuRent from '../components/pages/cpu-rent/CpuRent';
import NavBar from '../components/common-components/Nav/NavBar';
import Footer from "../components/common-components/footers/Footer";
import Zap from '../components/pages/zap/zap';
import ProMode from '../components/pages/pro-mode/pro-mode';
import LandingPage from '../components/pages/landing-page/landing-page';
import Transactions from '../components/pages/transactions/transactions';
import MobileFooter from '../components/common-components/mobile-footer/mobile-footer';


const Router = ({ual}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const getLoggedInUser = (ualData) => {
        if (ualData?.activeUser) {
            return ualData?.activeUser?.accountName
        } else return ""
    }

    const login = () => {
        ual.showModal();
    };

    const handleMenuClick = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };
    return (
        <div className="layout">
            <NavBar ual={ual} loggedInUser={getLoggedInUser(ual)} />
            <br />
            <Box sx={{minHeight: '90vh'}}>
                <Routes>
                    <Route exact path={ROUTES.HOME} element={<LandingPage ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route exact path={ROUTES.CPU_RENTAL} element={<CpuRent ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route exact path={ROUTES.TRANSACTIONS} element={<Transactions ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route exact path={ROUTES.ZAP} element={<Zap ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route exact path={ROUTES.LANDING_PAGE} element={<LandingPage ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route exact path={ROUTES.ADVANCE_MODE} element={<ProMode ual={ual} loggedInUser={getLoggedInUser(ual)} />} />
                    <Route path={ROUTES.REFERRAL_FORM} element={<Redirect loc="https://forms.gle/iuJwRyfE74UfMFZF9" />}
                    />
                    <Route path={ROUTES.FAQ} element={<Redirect loc="https://drive.google.com/file/d/1o-bGqaepXi3y-3_w8Kn2iL3IY_dPLZdr/view?usp=sharing" />}
                    />
                    <Route path={ROUTES.REFERRAL} element={<Redirect loc="/" />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Box>
            <Box>
                <MobileFooter
                    ual={ual}
                    loggedInUser={getLoggedInUser(ual)}
                    handleMenuClick={handleMenuClick}
                    menuOpen={menuOpen}
                    handleMenuCloses={handleMenuClose}
                />
            </Box>
            <Footer />
        </div>


    )
}

export default Router
