import './App.scss';
import Router from './router/router';
import Box from "@mui/material/Box";
import ErrorBoundary from "./error-boundary/error-boundary"

function App({ ual }) {
  if (!ual) return null;
  return (
    <ErrorBoundary>
      <Box className='app'>
        <Router ual={ual} />
      </Box>
    </ErrorBoundary>
  );
}

export default App;
