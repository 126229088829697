import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Avatar, Typography, Popover, Menu, MenuItem, AppBar, Toolbar} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import {ROUTES} from '../../../global/constant';
import AppBarLogo from '../../../assets/images/appBarLogo.png';
import NotificationImage from '../../../assets/images/notification.png';
import './NavBar.scss';
import {useRentCpuHandler} from '../../../global/helper';

function NavBar({ual, loggedInUser}) {
    const onRentCpuClick = useRentCpuHandler(loggedInUser);
    const navigate = useNavigate();
    const location = useLocation();
    const wallet = ual?.activeUser?.accountName
    const initials = () => {
        if (wallet) return (wallet[0] + wallet[1]).toUpperCase();
        return "user"
    }

    const logout = () => {
        ual.logout();
    }
    const login = () => {
        ual?.showModal();
    }
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState("");
    const handleNotificationClick = (event) => {
        setNotificationMessage("There are no new messages");
        setAnchorEl1(event.currentTarget);
    };
    const handleMenuOpen1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleMenuClose1 = () => {
        setAnchorEl1(null);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        ual.logout();
        handleMenuClose();
    };
    const handleLogin = () => {
        ual.logout();
        ual?.showModal();
        handleMenuClose();
    };

    return (
        <AppBar position="fixed" style={{background: '#1f0633'}}>
            <Toolbar className="flex-between-center" >
                <div className="marginLeftsm" style={{display: "flex", alignItems: "center", marginLeft: '2rem'}}>
                    <img src={AppBarLogo} onClick={() => navigate(ROUTES.HOME)} alt="this" style={{maxWidth: "10rem"}} />
                </div>
                <div className='marginRightsm' style={{display: "flex", alignItems: "center", marginRight: '2rem'}}>
                    <Typography variant="body1" className={location.pathname == '/transactions' ? 'active-link hide-on-md cursor-pointer' : 'non-active-link hide-on-md cursor-pointer'} onClick={() => navigate(ROUTES.TRANSACTIONS)}>Trace MyWAX</Typography>
                    <Typography variant="body1" className={location.pathname.includes('/cpu/') ? 'active-link hide-on-md cursor-pointer' : 'non-active-link hide-on-md cursor-pointer'} onClick={onRentCpuClick}><span className='mx-6'>Rent CPU</span></Typography>

                    {!loggedInUser ? (
                        <LoginIcon onClick={login} className='hide-on-md cursor-pointer' />
                    ) : (
                        <div className='hide-on-md'>
                            <Avatar
                                style={{height: "1.8rem", width: "1.8rem", marginRight: "1rem"}}
                                onClick={handleAvatarClick}
                            >
                                {initials()}
                            </Avatar>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    style: {
                                        width: "200px",
                                        height: "auto",
                                        background: "#1f0633",
                                        marginTop: '1rem'
                                    }
                                }}

                            >
                                <MenuItem style={{display: 'flex', justifyContent: 'center', background: '#1f0633'}}>{wallet.toUpperCase()}</MenuItem>

                                <hr style={{borderColor: "#c2c0c0", marginRight: '1rem', marginLeft: '1rem'}}></hr>
                                <MenuItem style={{background: '#1f0633'}} onClick={handleLogin}>Switch Account</MenuItem>
                                <MenuItem style={{background: '#1f0633'}} onClick={() => window.open('https://discord.com/invite/pnQaaMaps3', '_blank')}>
                                    Help
                                </MenuItem>


                                <hr style={{borderColor: "#c2c0c0", marginRight: '1rem', marginLeft: '1rem'}}></hr>
                                <MenuItem style={{background: '#1f0633'}} onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar