import React, {useState, useEffect} from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, TablePagination, Tooltip, FormControlLabel, Checkbox, Button, TextField} from '@mui/material';
import './transactions.scss'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ReactJson from 'react-json-view-with-toggle'

function ActionsTable({loading, filteredTransactions, getActions, accountName, getTableData, totalRecords}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [skip, setSkip] = useState(1000);
    const [isJsonCollapsed, setisJsonCollapsed] = useState(false);
    const [pageToJump, setPageToJump] = useState(1);

    useEffect(() => {
        setPage(0);
    }, [filteredTransactions, accountName])

    const handleToggleJson = () => {
        setisJsonCollapsed(!isJsonCollapsed);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page && (page + 1) * rowsPerPage >= 950) {
            getActions(accountName, skip);
            setSkip(skip + 1000);
            setPage(0);
            return
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        // getTableData(accountName, pageToJump, rowsPerPage)
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredTransactions.length - page * rowsPerPage);

    function isSameDay(date1, date2) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    async function tableDataOnDemand(pageToJump, rowsPerPage) {
        getTableData(accountName, pageToJump, rowsPerPage)
    }

    const lastPage = Math.ceil(totalRecords / rowsPerPage);

    return (
        <div style={{marginBottom: '4rem'}}>
            <TableContainer component={Paper} style={{background: '#13031c'}}>
                <Table>
                    <TableHead >
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">Date</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Action</Typography>
                            </TableCell>
                            <TableCell>
                                <FormControlLabel
                                    control={<Tooltip title="Toggle all JSON data">
                                        <div className="checkbox-wrapper">
                                            <Checkbox checked={!isJsonCollapsed} onChange={handleToggleJson} />
                                        </div>
                                    </Tooltip>}
                                    label={<Typography variant="h6">Data</Typography>}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Memo</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : filteredTransactions
                        ).map((transaction) => (
                            <TableRow key={transaction.global_sequence} style={{borderBottom: '2px solid #969696'}}>
                                <TableCell>
                                    <a className='transaction-link' href={`https://wax.bloks.io/transaction/${transaction.trx_id}`} target="_blank" rel="noopener noreferrer">
                                        <Tooltip title={new Date(transaction.timestamp).toLocaleString()}>
                                            <span className="transaction-timestamp" style={{textDecoration: "none", cursor: "pointer"}}>
                                                {isSameDay(new Date(transaction.timestamp), new Date()) ? (
                                                    new Date(transaction.timestamp).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
                                                ) : (
                                                    new Date(transaction.timestamp).toLocaleDateString('en-US', {month: 'numeric', day: 'numeric', year: '2-digit'})
                                                )}
                                            </span>
                                        </Tooltip>
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {transaction.act.name == 'Receive Token' || transaction.act.name == 'Send Token' ? (
                                        <span className={transaction.act.name == 'Receive Token' ? 'transaction-action-send' : 'transaction-action-receive'}>{transaction.act.name}</span>
                                    ) : (
                                        <span className='transaction-action-default'>{transaction.act.account}<ArrowRightAltIcon style={{verticalAlign: "middle"}} />{transaction.act.name}</span>
                                    )
                                    }
                                </TableCell>
                                <TableCell>
                                    {transaction.act.data?.from ? (
                                        <>
                                            {transaction.act.data.from}<ArrowRightAltIcon style={{verticalAlign: "middle"}} />{transaction.act.data.to}<span className='transaction-amount'>{transaction.act.data.quantity}</span>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{color: 'white'}}>
                                                <ReactJson src={transaction.act.data || {}} collapsed={isJsonCollapsed} theme={'tomorrow'} name={false} style={{color: 'white', background: 'transparent'}} />
                                            </div>
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{transaction.act.data?.memo}</TableCell>
                            </TableRow>
                        ))}
                        {loading ? (
                            <>
                                {[...Array(emptyRows)].map((_, index) => (
                                    <TableRow className="loading-text-skeleton" key={index}>
                                        <TableCell colSpan={6}>&nbsp;</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : filteredTransactions.length === 0 && !loading ? (
                            <TableRow>
                                <TableCell colSpan={6} style={{textAlign: 'center'}}>
                                    <Typography variant="h6" style={{color: '#969696'}}>
                                        No transactions found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 500, 1000]}
                component="div"
                count={filteredTransactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" style={{display: 'inline'}}>
                    Total Records: {totalRecords}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <span className='ml-5'>
                        <Button className='hide-on-sm' variant='contained' onClick={() => tableDataOnDemand(1, rowsPerPage)}>Page 1</Button>
                    </span>
                    <span className='mx-5'>
                        <Button className='hide-on-sm' variant='contained' onClick={() => tableDataOnDemand(lastPage, rowsPerPage)}>Page {lastPage}</Button>
                    </span>
                    <TextField className='hide-on-sm' value={pageToJump} onChange={(e) => {
                        if (e.target.value <= lastPage) {
                            setPageToJump(e.target.value)
                        } else {
                            setPageToJump(lastPage)
                        }
                    }} variant='filled' />
                    <span className='ml-5'>
                        <Button className='hide-on-sm' variant='contained' onClick={() => tableDataOnDemand(pageToJump, rowsPerPage)}>Jump To Page</Button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ActionsTable;