export const supportedSwaps = {
    "alcorSwap": {
        "code": "alcorammswap",
        "scope": "alcorammswap",
        "table": "pairs",
        "fee": 0.003,
        "memo": '$quantity $token@$contract',
        "contract_address": "alcorammswap"
    },
    "defibox": {
        "code": "swap.box",
        "scope": "swap.box",
        "table": "pairs",
        "fee": 0.003,
        "memo": 'swap,$quantity,$pairId',
        "contract_address": "swap.box"
    },
    "tacoSwap": {
        "code": "swap.taco",
        "scope": "swap.taco",
        "table": "pairs",
        "fee": 0.003,
        "memo": '$quantity $token@$contract',
        "contract_address": "swap.taco"
    }
}
