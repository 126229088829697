import "../cpu-rent/CpuRent.scss";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {
  Box,
  Container,
  TextField,
  Button,
  Autocomplete,
  Paper,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import smLogo from "../../../assets/images/smLogo.png";
import WaxImage from "../../../assets/images/wax.png";
import RamImage from "../../../assets/images/cpunowlogo.jpg";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import { WaxApi } from "../../../global/api/wax-api";
import Menu from "../../common-components/menu/menu";

import {
  ADD_STAKE_LABEL,
  RENEW_LABEL,
  RENTAL_LABEL,
} from "../../../global/constant";
import {
  NON_SUBSCRIBER_PRESETS,
  SUBSCRIBER_PRESETS,
} from "../../../global/cpu_rental/rental_constant";
import { ACCOUNT_NAME, API_STATUS, ROUTES } from "../../../global/constant";
import {
  toCamelCase,
  convertTime,
  getOptionsFromCache,
  updateOptionsCache,
  handleDeleteAllOptions,
  handleDeleteOption,
} from "../../../global/helpers/cpu-helpers";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../common-components/loading/loader";
import {
  createURL,
  sanitizeWallet,
  validateWallet,
} from "../../../global/helper";
import { Rental } from "../../../global/cpu_rental/rental";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Notification from "../../common-components/NotificationPopup/NotificationPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Disabled from "../../common-components/disabled/disabled";

function CpuRent({ loggedInUser, ual }) {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const refWallet = sanitizeWallet(searchParams.get("ref")) || ACCOUNT_NAME;
  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const defaultUser = { wallet: "" };
  let { wallet } = isObjectEmpty(params) ? defaultUser : params;

  wallet = wallet.replace(/%20/g, " ").trim().toLowerCase();
  const waxApi = new WaxApi();

  const [totalWAXBalance, setTotalWAXBalance] = useState(0);
  const [subscriber, setSubscriber] = useState(null);
  const [resources, setAccountResources] = useState(null);
  const [waxToUSDRate, setWaxToUSDRate] = useState(0);
  const [rentalPrice, setRentalPrice] = useState(0.00187);
  const [vipDiscount, setVipDiscount] = useState(100);
  const [investorData, setInvestorData] = useState(null);
  const [extraStake, setExtraStake] = useState(10);
  const [extraDays, setExtraDays] = useState(3);
  const [stake, setStake] = useState(100);
  const [day, setDay] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [wallet1, setwallet1] = useState(wallet);
  const [isDisabled, setIsDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [options, setOptions] = useState(getOptionsFromCache());
  const [key, setKey] = useState(0);
  const [isOverdue, setIsOverdue] = useState(false);
  const [nftHivePrice, setNftHivePrice] = useState(0.2188);
  const [cpuNowPrice, setCpuNowPrice] = useState(0.38);
  const [checked, setChecked] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("WAX");

  const [wufToWaxPrice, setWufToWaxPrice] = useState(0);

  useEffect(() => {
    const fetchWufToWaxPrice = async () => {
      try {
        const price = await waxApi.fetchWufToWaxRate();
        setWufToWaxPrice(1.1/price);
      } catch (error) {
        console.error('Failed to fetch WUF to WAX rate:', error);
      }
    };

    fetchWufToWaxPrice();
  }, []);

  function remainingTime() {
    const renewalDate = dayjs(subscriber?.renewal);
    const currentDate = dayjs();
    const diff = renewalDate.diff(currentDate, "hour");
    if (diff <= 0) {
      return <span style={{ color: "red" }}>Renewal overdue</span>;
    }
    const days = Math.floor(diff / 24);
    const hours = diff % 24;
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} and ${hours} hour${
        hours > 1 ? "s" : ""
      } `;
    } else {
      return `${hours} hour${hours > 1 ? "s" : ""} `;
    }
  }

  let initials = wallet[0] + wallet[1];
  const [dataLoadStatus, setDataLoadStatus] = useState(API_STATUS.NOT_STARTED);
  const [loading, setLoading] = useState(null);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{ color: "white" }}
            variant="caption"
            component="div"
            color="text.secondary"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const login = () => {
    ual?.showModal();
  };

  function getOptionsFromCache() {
    let options = JSON.parse(
      localStorage.getItem("autocompleteOptions") || "[]"
    );
    if (!options.includes(wallet) && wallet != "") {
      options.push(wallet);
      localStorage.setItem("autocompleteOptions", JSON.stringify(options));
    }
    return options;
  }

  function updateOptionsCache(options) {
    localStorage.setItem("autocompleteOptions", JSON.stringify(options));
  }

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDeleteOption = (option) => {
    const updatedOptions = options.filter((item) => item !== option);
    setOptions(updatedOptions);
    updateOptionsCache(updatedOptions);
  };

  const handleDeleteAllOptions = () => {
    setOptions([]);
    updateOptionsCache([]);
    setwallet1("");
    localStorage.removeItem("autocompleteOptions");
  };

  const renderedOptions = [...options];

  const updateSubscriber = () => {
    setDataLoadStatus(API_STATUS.LOADING);
    setLoading(true);
    const dataLoaders = [
      waxApi.getAccountResources(wallet1),
      waxApi.getWalletBalance(ACCOUNT_NAME),
      rentalService.subscriberData(wallet1),
    ];
    Promise.all(dataLoaders)
      .then((response) => {
        const [accountResources, walletBalance, subscriberData] = response;
        setAccountResources(accountResources);
        setTotalWAXBalance(walletBalance);
        if (subscriberData !== null) {
          setSubscriber(subscriberData);
        }

        setDataLoadStatus(API_STATUS.SUCCESS);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoadStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const rentalService = new Rental(ual, updateSubscriber, refWallet);

  const renewCpu = () => {
    if (extraDays < 3 || (extraDays >= 3 && extraDays % 3 !== 0)) {
      setErrorMessage("Only renewals in multiples of 3 days allowed");
    } else {
      rentalService.rentalTransaction(
        wallet1,
        subscriber.stake,
        extraDays,
        rentalPrice,
        RENEW_LABEL,
        vipDiscount,
        () => {},
        paymentMethod
      );
    }
  };

  useEffect(() => {
    return () => {
      console.log("user is ");
      if (ual.activeUser !== null) {
        console.log(ual.activeUser.accountName);
        const dataToBeLoaded = [
          rentalService.vipDiscount(),
          rentalService.investorData(),
        ];
        Promise.all(dataToBeLoaded)
          .then((response) => {
            const [discount, investor] = response;
            setVipDiscount(discount);
            setInvestorData(investor);
          })
          .catch((err) => {
            console.log(err);
            setDataLoadStatus(API_STATUS.FAILED);
          });
      }
    };
  }, [ual]);

  useEffect(() => {
    async function rentalPrice() {
      const rentalPricing = await rentalService.rate();
      const nfthivePrice = await rentalService.competitorData("nfthive");
      const cpuNowPrice = await rentalService.competitorData("cpunow");
      const walletBalance = await waxApi.getWalletBalance(ACCOUNT_NAME);
      const waxPrice = await waxApi.getWAXToUSDRate();
      setWaxToUSDRate(waxPrice ?? 0);
      setRentalPrice(rentalPricing);
      setTotalWAXBalance(walletBalance);
      setNftHivePrice(nfthivePrice);
      setCpuNowPrice(cpuNowPrice);
    }
    rentalPrice();
  }, [wallet1]);

  useEffect(() => {
    if (success || errorMessage) {
      const timeout = setTimeout(() => {
        setSuccess(false);
        setErrorMessage("");
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [success, errorMessage]);

  useEffect(() => {
    if (isDisabled) {
      setDataLoadStatus(API_STATUS.NOT_STARTED);
    }
  }, [dataLoadStatus, isDisabled]);

  useEffect(() => {
    if (wallet1 == "") setIsDisabled(true);
    if (wallet1 == "") setSubscriber(null) && setAccountResources(0);
    console.log("first");
    if (validateWallet(wallet1)) {
      setIsDisabled(false);
      setDataLoadStatus(API_STATUS.LOADING);
      setLoading(true);
      setSubscriber(null);
      const dataLoaders = [
        rentalService.subscriberData(wallet1),
        waxApi.getWAXToUSDRate(),
        waxApi.getAccountResources(wallet1),
        rentalService.rate(),
        waxApi.getWalletBalance(ACCOUNT_NAME),
        rentalService.competitorData("nfthive"),
        rentalService.competitorData("cpunow"),
      ];
      Promise.all(dataLoaders)
        .then((response) => {
          const [
            subscriberData,
            waxPrice,
            accountResources,
            rentalPricing,
            walletBalance,
            nfthivePrice,
            cpuNowPrice,
          ] = response;

          setWaxToUSDRate(waxPrice ?? 0);
          setAccountResources(accountResources);
          setRentalPrice(rentalPricing);
          setTotalWAXBalance(walletBalance);
          setNftHivePrice(nfthivePrice);
          setCpuNowPrice(cpuNowPrice);
          setSubscriber(subscriberData);
          const renewalDate = dayjs(subscriberData?.renewal);
          const currentDate = dayjs();

          const diff = renewalDate.diff(currentDate, "hour");
          console.log(diff, "diff");
          if (diff && diff <= 0) {
            setIsOverdue(true);
          } else {
            setIsOverdue(false);
          }
          console.log("subdata", subscriberData);

          setDataLoadStatus(API_STATUS.SUCCESS);
        })
        .catch((err) => {
          console.log(err);
          setDataLoadStatus(API_STATUS.FAILED);
        });
    }
  }, [wallet, wallet1]);

  return (
    <Box
      style={{
        font: "white",
        minHeight: "90vh",
      }}
    >
      {/* CURRENT RATE COMPONENT */}
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            border: "2px #5e4991 solid",
            borderRadius: "10px",
            minHeight: "10rem",
            maxWidth: "25rem",
            marginTop: "25px",
            width: "25rem",
            background: "var(--primary)",
            boxShadow: "0px 0px 10px 2px hsl(278, 39%, 14%)",
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            color=""
            style={{
              textAlign: "center",
              fontWeight: "bold",
              paddingTop: "0.5rem",
              color: "var(--golden)",
            }}
          >
            Current Rate
          </Typography>
          <Box
            className="flexcolumnsm flex-between-center"
            sx={{
              padding: "1rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={smLogo}
                alt=""
                style={{ maxWidth: "2.5rem", maxHeight: "1.8rem" }}
              />
              <Typography
                sx={{
                  fontSize: "clamp(1rem,1rem+10px,3rem)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  paddingLeft: "1rem",
                }}
              >
                {(rentalPrice * 100).toFixed(3)}% / Day
              </Typography>
            </div>
            <div
              className="hide-on-sm divider"
              style={{
                minHeight: "5rem",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem",
                }}
              >
                <img src={RamImage} alt="" style={{ maxWidth: "1.5rem" }} />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#969696",
                    paddingLeft: "0.5rem",
                  }}
                >
                  {cpuNowPrice.toFixed(3)}% / Day
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem",
                }}
              >
                <img src={WaxImage} alt="" style={{ maxWidth: "1.5rem" }} />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#969696",
                    paddingLeft: "0.5rem",
                  }}
                >
                  {nftHivePrice.toFixed(3)}% / Day
                </Typography>
              </div>
            </div>
          </Box>
         
        </Box>
      </Container>
      {/* RENTING FOR COMPONENT */}
      <Container 
      maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "1rem",

        }}
        >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            color: "#969696",
            paddingRight: "0.5rem",
          }}
        >
          Pay With
        </Typography>
        <FormControl variant="outlined" sx={{ minWidth: 120, border: "1px solid #5e4991", borderRadius: "4px" }}>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            label="Pay With"
          >
            <MenuItem value="WUF">WUF</MenuItem>
            <MenuItem value="WAX">WAX</MenuItem>
          </Select>
        </FormControl>
      </div>
      </Container>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        
        <Box
          className="flex"
          sx={{
            maxWidth: "60rem",
            border: "2px solid #5e4991",
            borderRadius: "10px",
            minHeight: "20rem",
            width: "60rem",
            marginTop: "0.5rem",
            background: "var(--primary)",
            boxShadow: "0px 0px 10px 2px hsl(278, 39%, 14%)",
            display: "flex",
            justifyContent: "space-evenly",
            padding: "1rem",
            color: "white",
            flexWrap: "wrap",
            position: "relative",
          }}
        >
     

          {isDisabled && <Disabled message="Please Enter Wallet" />}
          {dataLoadStatus === "failed" && <Disabled message="Wrong Wallet" />}
          {dataLoadStatus === "failed" && (
            <Notification type="error" message="Wallet not found" />
          )}
          {dataLoadStatus === "loading" && <Loader size={40} />}
          {dataLoadStatus === "success" && (
            <Notification message="Wallet Fetched Successfully" />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
              flexBasis: subscriber ? "30%" : "45%",
            }}
          >
            <Typography
              sx={{
                color: "#c3c1c1",
                fontSize: "1.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              Renting For &nbsp; &nbsp;
              <div className="pro-mode-switch">
                <Switch
                  color="default"
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                    navigate(ROUTES.ADVANCE_MODE);
                  }}
                />
                <Typography variant="caption">Pro Mode</Typography>
              </div>
            </Typography>

            <Box
              sx={{
                borderRadius: "10px",
                border: "2px solid #5e4991",
                padding: "0.1rem",
                width: "18rem",
                textAlign: "center",
              }}
              style={{ position: "relative", zIndex: 1 }}
            >
              <Autocomplete
                disabled={dataLoadStatus === "loading"}
                onBlur={(event) => {
                  const inputValue = event.target.value;
                  if (inputValue && !options.includes(inputValue)) {
                    const updatedOptions = [...options, inputValue];
                    setOptions(updatedOptions);
                    updateOptionsCache(updatedOptions);
                  }
                  setwallet1(inputValue);
                  // navigate('/cpu/'+inputValue)
                }}
                options={renderedOptions}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    if (!options.includes(newValue)) {
                      const updatedOptions = [...options, newValue];
                      setOptions(updatedOptions);
                      updateOptionsCache(updatedOptions);
                    }
                    setwallet1(newValue);
                    setIsOverdue(false);
                  }
                }}
                value={wallet1}
                key={key}
                renderInput={(params) => (
                  <div style={{display: "flex", alignItems: "center"}}>
                    <TextField
                      {...params}
                      placeholder="Select Account"
                      InputProps={{
                        ...params.InputProps,
                        sx: {bgcolor: "var(--primary)", color: "white"},
                        style: {color: "white"},
                      }}
                    />

                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleDeleteAllOptions();
                        setOptions([]);
                        updateOptionsCache([]);
                      }}
                      sx={{display: "flex", justifyContent: "center"}}
                    >
                      <IconButton
                        size="small"
                        aria-label="delete all"
                        onClick={(event) => {
                          console.log("working");
                          event.stopPropagation();
                          event.preventDefault();
                          handleDeleteAllOptions();
                          setOptions([]);
                          updateOptionsCache([]);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {/* <span>Clear All</span> */}
                    </div>
                  </div>
                )}
                freeSolo
                PopperComponent={({children}) => (
                  <Paper
                    sx={{
                      bgcolor: "#40006a",
                      position: "absolute",
                      width: "100%",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderOption={(props, option, index) =>
                  index === options.length ? (
                    <></>
                  ) : (
                    <div
                      {...props}
                      style={{display: "flex", justifyContent: "space-between"}}
                    >
                      <Typography
                        className="autocomplete-paper"
                        variant="h8"
                        color="initial"
                        style={{
                          color: "white",
                        }}
                      >
                        {" "}
                        {option}
                      </Typography>

                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOption(option);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )
                }
              />

              {/* <ArrowDropDownIcon /> */}
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.5rem",
              }}
            >
              <Typography sx={{padding: "0.5rem"}}>
                <span style={{color: "var(--golden)", fontWeight: "bold"}}>
                  CPU{" "}
                </span>
                <span style={{color: "#c3c1c1", fontWeight: "bold"}}>
                  {wallet1 == "" ? 0 : convertTime(resources?.cpu_limit?.used)}/
                  {wallet1 == ""
                    ? 0
                    : convertTime(resources?.cpu_limit?.available)}
                </span>
              </Typography>
              <CircularProgressWithLabel
                variant="determinate"
                style={{color: "#1d905b", height: "2rem", width: "2rem"}}
                value={
                  wallet1 == ""
                    ? 0
                    : (resources?.cpu_limit?.used /
                        resources?.cpu_limit?.available) *
                      100
                }
              />
            </div>
          </div>
          <div
            className="hide divider"
            style={{
              height: "11rem",
            }}
          ></div>

          {subscriber ? (
            <div className="" style={{flexBasis: "55%"}}>
              <div
                className="flexcolumnsm"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0.3rem",
                  flex: "1",
                }}
              >
                <div
                  className="mobileBox"
                  style={{padding: "1rem", flex: "1", position: "relative"}}
                >
                  {isOverdue && <Disabled message="renewal overdue" />}
                  <Typography className="gold-fontsm">Current Stake</Typography>
                  <Typography
                    className="bold-fontsm"
                    style={{
                      color: "white",
                    }}
                  >
                    {subscriber?.stake?.toFixed(2)} WAX
                  </Typography>
                  <div className="text-input">
                    <TextField
                      size="small"
                      className=""
                      value={extraStake}
                      onChange={(event) => {
                        if (isNaN(Number(event.target.value))) {
                          event.target.value = "";
                          setErrorMessage("Please Enter a valid number");
                          return;
                        }
                        setErrorMessage("");
                        setExtraStake(Number(event.target.value));
                      }}
                    ></TextField>
                    {errorMessage && (
                      <Notification type="error" message={errorMessage} />
                    )}
                    <Typography
                      style={{
                        color: "#969696",
                        fontWeight: "bold",
                        padding: "0.2rem",
                      }}
                    >
                      WAX
                    </Typography>
                  </div>
                  <Button
                    className=""
                    variant="contained"
                    style={{
                      background: "#4e0280",
                      margin: "0.4rem",
                      margin: "0.4rem auto",
                      display: "block",
                    }}
                    onClick={() => {
                      if (!ual?.activeUser)
                        return login().then(() => {
                          rentalService.rentalTransaction(
                            wallet1,
                            extraStake,
                            Math.round(
                              (subscriber.renewal - new Date()) / 86400000
                            ),
                            rentalPrice,
                            ADD_STAKE_LABEL,
                            vipDiscount,
                            (e) => {
                              if (e == "Success") return setSuccess(true);
                              setErrorMessage(e.message);
                              console.warn(typeof e.message);
                            },
                            paymentMethod
                          );
                        });
                      rentalService.rentalTransaction(
                        wallet1,
                        extraStake,
                        Math.round(
                          (subscriber.renewal - new Date()) / 86400000
                        ),
                        rentalPrice,
                        ADD_STAKE_LABEL,
                        vipDiscount,
                        (e) => {
                          if (e == "Success") return setSuccess(true);
                          setErrorMessage(e.message);
                          console.warn(typeof e.message);
                        },
                        paymentMethod
                      );
                    }}
                  >
                    {Rental.rentalCharges(
                      extraStake,
                      Math.round((subscriber.renewal - new Date()) / 86400000),
                      rentalPrice,
                      false,
                      vipDiscount,
                      (paymentMethod === "WUF" ? wufToWaxPrice : 1),

                    ).toFixed(3)}{" "}
                    {paymentMethod}
                  </Button>
                </div>
                <div
                  className="hide divider"
                  style={{
                    height: "11rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                ></div>

                <div
                  className="mobileBox"
                  style={{padding: "1rem  ", flex: "1"}}
                >
                  <Typography className="gold-fontsm">
                    Expiring Within
                  </Typography>
                  <Typography
                    className="bold-fontsm"
                    style={{
                      color: "white",
                    }}
                  >
                    {remainingTime()}
                  </Typography>
                  <div className="text-input">
                    <TextField
                      size="small"
                      className=""
                      value={extraDays}
                      onChange={(event) => {
                        const input = event.target.value;
                        if (isNaN(Number(input))) {
                          event.target.value = "";
                          setErrorMessage("Please Enter a valid number");
                          return;
                        }
                        const inputValue = Math.ceil(Number(input));
                        setExtraDays(inputValue);
                      }}
                      onBlur={(event) => {
                        const input = event.target.value;
                        const inputValue = Math.floor(Number(input));

                        if (inputValue % 3 !== 0) {
                          setErrorMessage("Please enter a multiple of 3");
                          setExtraDays(inputValue + 3 - (inputValue % 3));
                          return;
                        }
                        setErrorMessage("");
                      }}
                    ></TextField>
                    {errorMessage && (
                      <Notification type="error" message={errorMessage} />
                    )}
                    {success && (
                      <Notification type="success" message="Success" />
                    )}

                    <Typography
                      style={{
                        color: "#969696",
                        fontWeight: "bold",
                        padding: "0.2rem",
                      }}
                    >
                      DAYS
                    </Typography>
                  </div>
                  <Button
                    className=""
                    variant="contained"
                    style={{
                      background: "#4e0280",
                      marginTop: "0.4rem",
                      maxHeight: "3rem",
                      margin: "0.4rem auto",
                      display: "block",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      if (!ual?.activeUser)
                        return login().then(() => {
                          renewCpu();
                        });
                      renewCpu();
                    }}
                  >
                    <del style={{color: "green", paddingRight: "0.3rem"}}>
                      <span style={{color: "#bdc1c6"}}>
                        {(
                          Rental.rentalCharges(
                            subscriber.stake,
                            extraDays,
                            rentalPrice,
                            true,
                            vipDiscount,
                            (paymentMethod === "WUF" ? wufToWaxPrice : 1),

                          ) /
                          (1 - 0.05)
                        ).toFixed(2)}
                      </span>
                    </del>
                    {Rental.rentalCharges(
                      subscriber.stake,
                      extraDays,
                      rentalPrice,
                      true,
                      vipDiscount,
                      (paymentMethod === "WUF" ? wufToWaxPrice : 1),
                    ).toFixed(2)}{" "}
                    {paymentMethod}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className=""
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "0.3rem",
                flex: "1",
              }}
            >
              <div
                className="flexcolumnsm mobileBox"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0.3rem",
                  flex: "1",
                }}
              >
                <div className="" style={{padding: "0.5rem", flex: "1"}}>
                  <div>
                    <Typography
                      className="gold-fontsm"
                      style={{
                        padding: "0.5rem",
                      }}
                    >
                      CPU STAKE
                    </Typography>
                    <div className="text-input">
                      <TextField
                        size="small"
                        className=""
                        value={stake}
                        onChange={(event) => {
                          if (isNaN(Number(event.target.value))) {
                            event.target.value = "";
                            setErrorMessage("Please Enter a valid number");
                            return;
                          }
                          setErrorMessage("");
                          setStake(Number(event.target.value));
                        }}
                      ></TextField>
                      {errorMessage && (
                        <Notification type="error" message={errorMessage} />
                      )}
                      <Typography
                        style={{
                          color: "#969696",
                          fontWeight: "bold",
                          padding: "0.2rem",
                        }}
                      >
                        WAX
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="" style={{padding: "0.5rem", flex: "1"}}>
                  <Typography
                    className="bold-fontsm"
                    style={{
                      color: "var(--golden)",
                      padding: "0.5rem",
                    }}
                  >
                    HOW MANY DAYS
                  </Typography>
                  <div className="text-input">
                    <TextField
                      size="small"
                      className=""
                      value={day}
                      onChange={(event) => {
                        const input = event.target.value;
                        if (isNaN(Number(input))) {
                          event.target.value = "";
                          setErrorMessage("Please Enter a valid number");
                          return;
                        }
                        setErrorMessage("");
                        setDay(input);
                      }}
                    ></TextField>
                    {errorMessage && (
                      <Notification type="error" message={errorMessage} />
                    )}
                    <Typography
                      style={{
                        color: "#969696",
                        fontWeight: "bold",
                        padding: "0.2rem",
                      }}
                    >
                      DAYS
                    </Typography>
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    flexBasis: "100%",
                    width: "15rem",
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1rem",
                  }}
                >
                  <Button
                    className=""
                    variant="contained"
                    style={{
                      background: "#4e0280",
                      margin: "1rem",
                      alignItems: "center",
                      justifySelf: "center",
                    }}
                    onClick={() => {
                      if (!ual?.activeUser)
                        return login().then(() => {
                          rentalService.rentalTransaction(
                            wallet1,
                            stake,
                            day,
                            rentalPrice,
                            RENTAL_LABEL,
                            vipDiscount,
                            (e) => {
                              if (e == "Success") return setSuccess(true);

                              setErrorMessage(e.message);
                              console.warn(typeof e.message);
                            },
                            paymentMethod
                          );
                        });
                      rentalService.rentalTransaction(
                        wallet1,
                        stake,
                        day,
                        rentalPrice,
                        RENTAL_LABEL,
                        vipDiscount,
                        (e) => {
                          if (e == "Success") return setSuccess(true);

                          setErrorMessage(e.message);
                          console.warn(typeof e.message);
                        },
                        paymentMethod
                      );
                    }}
                  >
                    {Rental.rentalCharges(
                      stake,
                      day,
                      rentalPrice,
                      false,
                      vipDiscount,
                      (paymentMethod === "WUF" ? wufToWaxPrice : 1),
                    ).toFixed(3)}{" "}
                    {paymentMethod}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {/* suggestion buttons */}
          <div
            className="wrap"
            style={{
              flexBasis: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {subscriber
              ? SUBSCRIBER_PRESETS.map((preset) => {
                  return (
                    <Button
                      className="buttons"
                      variant="outlined"
                      onClick={() => {
                        if (!ual?.activeUser) return login();
                        rentalService.rentalTransaction(
                          wallet1,
                          preset.stake ? preset.stake : subscriber.stake,
                          preset.days
                            ? preset.days
                            : Math.round(
                                (subscriber.renewal - new Date()) / 86400000
                              ),
                          rentalPrice,
                          preset.stake ? ADD_STAKE_LABEL : RENEW_LABEL,
                          vipDiscount,
                          (e) => {
                            if (e == "Success") return setSuccess(true);

                            setErrorMessage(e.message);
                            console.warn(typeof e.message);
                          },
                          paymentMethod
                        );
                      }}
                      style={{position: "relative"}}
                    >
                      {isOverdue && <Disabled message="renewal overdue" />}
                      <Typography className="suggestion-buttons">
                        +{" "}
                        {preset.stake
                          ? preset.stake + " WAX"
                          : preset.days + " DAYS"}
                      </Typography>
                    </Button>
                  );
                })
              : NON_SUBSCRIBER_PRESETS.map((preset) => {
                  return (
                    <Button
                      className="buttons"
                      variant="outlined"
                      onClick={() => {
                        if (!ual?.activeUser) return login();
                        rentalService.rentalTransaction(
                          wallet1,
                          preset.stake,
                          preset.days,
                          rentalPrice,
                          RENTAL_LABEL,
                          vipDiscount,
                          (e) => {
                            if (e == "Success") return setSuccess(true);

                            setErrorMessage(e.message);
                            console.warn(typeof e.message);
                          },
                          paymentMethod
                        );
                      }}
                    >
                      <Typography className="suggestion-buttons">
                        Stake {preset.stake} WAX - {preset.days} days
                      </Typography>
                    </Button>
                  );
                })}
          </div>
        </Box>
      </Container>
      {/*FOOTER */}

      <Box
        className="hidden-on-lg"
        style={{
          position: "fixed",
          bottom: "0",
          minHeight: "2rem",
          width: "100%",
          background: "var(--primary)",
          marginTop: "2rem",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          zIndex: "1",
        }}
      >
        <HomeIcon style={{color: "white"}} />
        <IconButton onClick={handleMenuClick}>
          <MenuIcon style={{color: "white"}} />
        </IconButton>
        {menuOpen && (
          <Menu
            activeItem="RentCpu"
            onItemClick={handleMenuClose}
            onClose={handleMenuClose}
          />
        )}
        <LoginIcon style={{color: "white"}} />
      </Box>
    </Box>
  );
}

export default CpuRent;
