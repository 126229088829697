import { Box, Container } from '@mui/material'
import React, {useEffect, useState} from 'react'
import MyTable from './table'
import "./scss/pro-mode.scss"
import {InputAdornment, LinearProgress, MenuItem, Select, Switch, TextField, Typography} from '@mui/material'
import PropTypes from 'prop-types';
import FilterImage from '../../../assets/images/filter.png'
import SearchIcon from '@mui/icons-material/Search';
import CustomerModal from './modals/customer-modal'
import ImportModal from './modals/import-modal'
import BulkActions from './bulk-actions'
import {
    ACCOUNT_NAME,
    ADD_STAKE_LABEL,
    API_STATUS,
    CACHE_KEY,
    EXISTING_LABELS,
    USER_TYPE
} from '../../../global/constant';
import {
    clearFromLocalStorage,
    getSubscriberFromLocalStorage,
    sanitizeWallet,
    setSubscriberInLocalStorage,
    validateWallet
} from '../../../global/helper';
import {Rental} from "../../../global/cpu_rental/rental";
import {useSearchParams, useNavigate} from "react-router-dom";
import Papa from "papaparse";
import csvTemplate from '../../../assets/csv-template/csvTemplate.csv'
import {ModalButtons} from "./modals/modal-buttons";
import Notification from '../../common-components/NotificationPopup/NotificationPopup'
import ROUTES from "../../../global/constant";
import {all} from 'axios'

ModalButtons.propTypes = {
    onClick: PropTypes.func,
    onClick1: PropTypes.func,
    onClick2: PropTypes.func
};

function ProMode({loggedInUser, ual}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const refWallet = sanitizeWallet(searchParams.get('ref')) || ACCOUNT_NAME;
    let storedUsers = getSubscriberFromLocalStorage(CACHE_KEY.SUBSCRIBER);
    const [dataLoadStatus, setDataLoadStatus] = useState(API_STATUS.NOT_STARTED)
    const [allSubscribers, setAllSubscribers] = useState([])
    const [existingUsersData, setExistingUsersData] = useState([]);
    const [newUsersData, setNewUsersData] = useState([]);
    const [vipDiscount, setVipDiscount] = useState(100);
    const [rentalPrice, setRentalPrice] = useState(0);
    const [users, setUsers] = useState("")
    const [userType, setUserType] = useState(USER_TYPE.ALL_USER);
    const [userFilter, setUserFilter] = useState(USER_TYPE.ALL_USER)
    const [newPresetDays, setNewPresetDays] = useState(1)
    const [newPresetStake, setNewPresetStake] = useState(50)
    const [searchField, setSearchField] = useState("")
    const [checked, setChecked] = useState(true)
    const [openImportModel, setOpenImportModel] = React.useState(false);
    const handleOpenImportModel = () => setOpenImportModel(true);
    const handleCloseImportModal = () => setOpenImportModel(false);
    const [openCustomerModel, setOpenCustomerModel] = React.useState(false);
    const handleOpenCustomerModel = () => setOpenCustomerModel(true);
    const handleCloseCustomerModal = () => setOpenCustomerModel(false)
    const [csvData, setCsvData] = useState(null);
    const [existingStake, setExistingStake] = useState(50)
    const [existingDay, setExistingDay] = useState(3)
    const [success, setSuccess] = useState(null)
    const [errorMessage, setErrorMessage] = useState(false)
    // const [existingPreset1, setExistingPreset1] = useState(100)
    // const [existingPreset2, setExistingPreset2] = useState(3)
    const login = () => {
        ual?.showModal();
    };
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', paddingTop: '0.5rem', color: '#5e4991', borderRadius: '3px'}}>
                <Box sx={{minWidth: 35, textAlign: 'center'}}>
                    <Typography variant="body2" color="white" style={{verticalAlign: 'end'}}>{`${Math.round(props.value)}%`}</Typography>
                </Box>
                <Box sx={{width: '100%', mr: 1, color: '#230838'}}>
                    <LinearProgress variant="determinate" {...props} style={{color: "#b093f6 !important", height: 10}} />
                </Box>
            </Box>
        );
    }
    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    const handleCsvFile = (file) => {
        Papa.parse(file, {
            header: true,
            delimiter: "\t",
            complete: (results) => {
                const cleanedData = results.data.map((row) => {
                    const cleanedRow = {};
                    Object.keys(row).forEach((key) => {
                        cleanedRow[key] = row[key].trim(); // Remove leading/trailing whitespace
                        if (cleanedRow[key] === "") delete cleanedRow[key]; // Remove empty cells
                    });
                    return cleanedRow;
                });
                const valuesOnly = cleanedData.map((row) => Object.values(row)).flat();
                const concatenatedValues = valuesOnly.reduce((acc, curr) => acc + " " + curr, "");
                setUsers(concatenatedValues);
                setCsvData(concatenatedValues)
            },
            error: (error) => {
                console.error(error);
                setErrorMessage(error.message);
            },
        });
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        handleCsvFile(file);
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        handleCsvFile(file);
    };

    const getVipDiscount = () => {
        if (loggedInUser === "yedsa.wam") {
            return 80
        } else if (loggedInUser === ".an1..c.wam") {
            return 94
        } else if (loggedInUser === 'nzoeg.wam') {
            return 80
        } else if (loggedInUser === 'waxptoolsadm') {
            return 80
        }
        else {
            return 100
        }
    }

    const addUsersToLocal = () => {
        const validUser = [];
        const invalidUser = [];
        const usersList = users.trim().split(/[\s,\n]+/).filter(user => user !== '').map(user => sanitizeWallet(user))

        usersList.forEach((user) => {
            if (!!validateWallet(user)) {
                validUser.push(user);
            } else {
                invalidUser.push(user);
            }
        })
        if (invalidUser.length) {
            setErrorMessage(`Invalid Users: ${invalidUser.join(', ')}`);
        } else {
            let unionUser = [...new Set([...validUser, ...storedUsers])];
            storedUsers = unionUser
            setSubscriberInLocalStorage(unionUser)
            if (users.length > 1) {
                setSuccess("Successfully Added Wallets")
            }
            setUsers('');
            filterUsers();
            setUsers("")
        }
    }

    const filterUsers = async (allSubs = '') => {
        let allSubscribersData = allSubs ? allSubs : allSubscribers;
        let subscriberMap = await allSubscribersData.reduce((map, subscriber) => {
            map[subscriber.account] = subscriber;
            return map;
        }, {});
        let existingSubscribers = [];
        let newSubscribers = [];
        for (let i = 0; i < storedUsers.length; i++) {
            if (storedUsers[i] in subscriberMap) {
                existingSubscribers.push(subscriberMap[storedUsers[i]])
            } else {
                newSubscribers.push({
                    account: storedUsers[i],
                    stake: 0,
                    renewal: 0
                })
            }
        }
        setExistingUsersData([...existingSubscribers])
        setNewUsersData([...newSubscribers])
    }

    const updateSubscriber = () => {
    }

    const rentalService = new Rental(ual, updateSubscriber, refWallet);

    const handleRental = (wallet, extraStake, extraDays, label) => {
        if (!loggedInUser) {
            login();
            return;
        }
        rentalService.rentalTransaction(
            wallet,
            extraStake,
            extraDays,
            rentalPrice,
            label,
            getVipDiscount(),
            (e) => {
                if (e === "Success") {
                    setSuccess("Success");
                    window.location.reload();
                } else {
                    setErrorMessage(e.message);
                    console.warn(typeof e.message);
                }
            }
        );
    };

    const totalCharges = (stakeAdded, days, type) => {
        let totalCost = 0
        if (EXISTING_LABELS.includes(type)) {
            existingUsersData.forEach(subscriber => {
                if (stakeAdded !== null && days === null) {
                    const renewalDays = (subscriber.renewal - new Date()) / 86400000
                    if (renewalDays < 0) return
                    totalCost += Rental.rentalCharges(stakeAdded, renewalDays, rentalPrice, false, getVipDiscount())
                } else if (stakeAdded === null && days !== null) {
                    totalCost += Rental.rentalCharges(subscriber.stake, days, rentalPrice, true, getVipDiscount())
                }
            })
        } else {
            newUsersData.forEach(subscriber => {
                if (stakeAdded > 0 && days > 0) {
                    totalCost += Rental.rentalCharges(stakeAdded, days, rentalPrice, false, getVipDiscount())
                }
            })
        }
        return totalCost
    }

    const bulkRent = (stakeAdded, days, type) => {
        if (!loggedInUser) {
            login();
            return;
        }

        let actions = [];
        if (EXISTING_LABELS.includes(type)) {
            existingUsersData.forEach((subscriber) => {
                const memo = rentalService.rentalMemo(type, subscriber.account);
                if (type === ADD_STAKE_LABEL) {
                    const renewalDays = (subscriber.renewal - new Date()) / 86400000;
                    if (renewalDays < 0) return;
                    const cost = Rental.rentalCharges(
                        stakeAdded,
                        renewalDays,
                        rentalPrice,
                        false,
                        getVipDiscount(),
                        (e) => {
                            if (e === "Success") {
                                // setSuccess("Success");
                                // window.location.reload();
                            } else {
                                setErrorMessage(e.message);
                                console.warn(typeof e.message);
                            }
                        }
                    );
                    actions.push(rentalService.rentalAction(cost, memo));
                } else {
                    const cost = Rental.rentalCharges(
                        subscriber.stake,
                        days,
                        rentalPrice,
                        true,
                        getVipDiscount(),
                        (e) => {
                            if (e === "Success") {
                                // setSuccess("Success");
                                // window.location.reload();
                            } else {
                                setErrorMessage(e.message);
                                console.warn(typeof e.message);
                            }
                        }
                    );
                    actions.push(rentalService.rentalAction(cost, memo));
                }
            });
        } else {
            newUsersData.forEach((subscriber) => {
                const cost = Rental.rentalCharges(
                    stakeAdded,
                    days,
                    rentalPrice,
                    false,
                    getVipDiscount(),
                    (e) => {
                        if (e === "Success") {
                            // setSuccess("Success");
                            // window.location.reload();
                        } else {
                            setErrorMessage(e.message);
                            console.warn(typeof e.message);
                        }
                    }
                );
                const memo = rentalService.rentalMemo(type, subscriber.account, days);
                actions.push(rentalService.rentalAction(cost, memo));
            });
        }
        setDataLoadStatus(API_STATUS.LOADING);
        rentalService
            .batchedTransactions(actions)
            .then((succ) => {
                console.log("i am successful")
                window.alert("success")
                // if (succ.success) {
                //     setSuccess("Success");
                //     window.location.reload();
                // }
                // if (succ.success === false) {
                //     setErrorMessage("Transaction Failed");
                // }
                setDataLoadStatus(API_STATUS.SUCCESS);
            })
            .catch((e) => {
                setDataLoadStatus(API_STATUS.FAILED)
                setErrorMessage(e.message);
            }
            );
    };

    const bulkRentForNewUser = (stakeAdded, days, type, newUsersData) => {
        if (!loggedInUser) {
            login();
            return;
        }

        const actions = newUsersData.map((subscriber) => {
            const cost = Rental.rentalCharges(
                stakeAdded,
                days,
                rentalPrice,
                false,
                getVipDiscount(),
                (e) => {
                    if (e === "Success") {
                        setSuccess("Success");
                        window.location.reload();
                    } else {
                        setErrorMessage(e.message);
                        console.warn(typeof e.message);
                    }
                }
            );
            const memo = rentalService.rentalMemo(type, subscriber.account, days);
            return rentalService.rentalAction(cost, memo);
        });

        setDataLoadStatus(API_STATUS.LOADING);
        rentalService
            .batchedTransactions(actions)
            .then((succ) => {
                setSuccess("Success");
                setDataLoadStatus(API_STATUS.SUCCESS);
                window.location.reload();
            })
            .catch((e) => {
                setErrorMessage(e.message);
                setDataLoadStatus(API_STATUS.FAILED)
            });
    };

    const deleteFromCache = (users) => {
        clearFromLocalStorage(CACHE_KEY.SUBSCRIBER, users);
        storedUsers = getSubscriberFromLocalStorage(CACHE_KEY.SUBSCRIBER);
        filterUsers()
    }
    const allUsers = [...existingUsersData, ...newUsersData]
    const searchFieldResult = allUsers.filter((user) => user.account.includes(searchField));

    useEffect(() => {
        if (errorMessage || success) {
            setTimeout(() => {
                setErrorMessage(null);
                setSuccess(null);
            }, 3000);
        }
    }, [errorMessage, success]);

    useEffect(() => {
        filterUsers();
    }, [allSubscribers.length])

    useEffect(() => {
        addUsersToLocal()
    }, [csvData])

    useEffect(() => {
        return () => {
            if (ual && ual?.activeUser !== null) {
                console.log(loggedInUser)
                const dataToBeLoaded = [rentalService.vipDiscount()]
                Promise.all(dataToBeLoaded).then(response => {
                    const [discount] = response
                    setVipDiscount(discount)
                    setDataLoadStatus(API_STATUS.SUCCESS)
                }).catch(err => {
                    console.log(err)
                    setDataLoadStatus(API_STATUS.FAILED)
                })
            }
        };
    }, [ual]);

    useEffect(() => {
        setDataLoadStatus(API_STATUS.LOADING);
        const dataLoaders = [rentalService.rate(), rentalService.subscriberDataForAdvanceMode()]
        Promise.all(dataLoaders).then(async (response) => {
            const [rentalPricing, subscriberData] = response
            setRentalPrice(rentalPricing)
            setAllSubscribers([...subscriberData]);
            // await sleep(10);
            filterUsers([...subscriberData])
            setDataLoadStatus(API_STATUS.SUCCESS)
        }).catch(err => {
            console.log(err)
            setDataLoadStatus(API_STATUS.FAILED)
        })
    }, [])

    return (
        <Container style={{
            font: "white",
            minHeight: "80vh",
            paddingTop: '2rem'
        }}>
            {errorMessage && <Notification message={errorMessage} type="error" />}
            {success && <Notification message={success} type="success" />}
            <Box className="flex-center-column padding-zero-md" style={{padding: '3rem'}}>
                <div className='flex-between width-full' style={{marginBottom: '1rem'}}>
                    <Typography color="white" style={{fontSize: 'var(--fontlg)', fontWeight: 'bold'}}>CPU
                        RENTAL</Typography>
                    <div className="pro-mode-switch">
                        <Switch
                            color="default"
                            checked={checked}
                            onChange={(event) => {
                                setChecked(event.target.checked);
                                if (!event.target.checked) {
                                    const userToNavigate = loggedInUser ? loggedInUser : (allUsers.length > 0 ? allUsers[0].account : null);
                                    if (userToNavigate) {
                                        navigate(`/cpu/${userToNavigate}`);
                                    } else {
                                        navigate('/cpu');
                                    }
                                }
                            }}
                        />
                        <Typography variant="caption">Pro Mode</Typography>
                    </div>
                </div>
                <div className='flex-between width-full'>
                    <Select
                        value={userType}
                        onChange={(event) => {
                            setUserType(event.target.value);
                            setUserFilter(event.target.value)
                        }}
                        size='small'
                        sx={{
                            background: '#230f34',
                            color: 'white',
                            width: '11rem'
                        }}
                        className="hide-on-sm"
                    >
                        <MenuItem value={USER_TYPE.ALL_USER}>All Users</MenuItem>
                        <MenuItem value={USER_TYPE.EXISTING_USER}>Existing User</MenuItem>
                        <MenuItem value={USER_TYPE.NEW_USER}>New User</MenuItem>
                    </Select>

                    <Box className='flex-end width-full flex-around-sm' mb={1}>
                        <ModalButtons onClick={() => {
                            deleteFromCache(storedUsers);
                        }} onClick1={handleOpenImportModel} onClick2={handleOpenCustomerModel} />

                        <ImportModal
                            openImportModel={openImportModel}
                            handleCloseImportModal={handleCloseImportModal}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            handleFileInputChange={handleFileInputChange}
                            csvTemplate={csvTemplate}
                        />

                        <CustomerModal
                            open={openCustomerModel}
                            onClose={handleCloseCustomerModal}
                            value={users}
                            onChange={(e) => setUsers(e.target.value)}
                            addUsersToLocal={addUsersToLocal}
                        />
                    </Box>
                </div>
                <div className='flex-between flex-column-sm' style={{width: '100%'}}>
                    <TextField
                        placeholder="Filter"
                        className="border-thin hide-on-sm search-field"
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={FilterImage} style={{color: 'white', width: '1rem'}} alt="filter" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Select
                        value={userType}
                        onChange={(event) => {
                            setUserType(event.target.value);
                            setUserFilter(event.target.value)
                        }} size='small'
                        className="hide-on-lg show-on-sm user-select"
                    >
                        <MenuItem value={USER_TYPE.ALL_USER}>All Users</MenuItem>
                        <MenuItem value={USER_TYPE.EXISTING_USER}>Existing User</MenuItem>
                        <MenuItem value={USER_TYPE.NEW_USER}>New User</MenuItem>
                    </Select>
                    <TextField
                        value={searchField}
                        onChange={(e) => {
                            setSearchField(e.target.value)
                        }}
                        onBlur={searchFieldResult}
                        placeholder="Search"
                        className="border-thin search-on-sm"
                        style={{marginBottom: '1rem', width: '19.2rem'}}
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{color: 'white'}} />
                                </InputAdornment>),
                        }}
                    />

                </div>
                <MyTable
                    newUsers={newUsersData}
                    existingUsers={existingUsersData}
                    searchFieldResult={searchFieldResult}
                    vipDiscount={vipDiscount}
                    rentalPrice={rentalPrice}
                    userFilter={userFilter}
                    handleRental={handleRental}
                    deleteFromCache={deleteFromCache}
                    loggedInUser={loggedInUser}
                    bulkRent={bulkRent}
                    bulkRentForNewUser={bulkRentForNewUser}
                    totalCharges={totalCharges}
                    login={login}
                />
                <BulkActions
                    setErrorMessage={setErrorMessage}
                    newPresetStake={newPresetStake}
                    setNewPresetStake={setNewPresetStake}
                    newPresetDays={newPresetDays}
                    setNewPresetDays={setNewPresetDays}
                    existingStake={existingStake}
                    setExistingStake={setExistingStake}
                    existingDay={existingDay}
                    setExistingDay={setExistingDay}
                    bulkRent={bulkRent}
                    totalCharges={totalCharges}
                />
            </Box>
        </Container>
    )
}
export default ProMode
