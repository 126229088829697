import dayjs from "dayjs";

// export function remainingTime() {
//     // const renewalDate = dayjs(subscriber?.renewal);
//     const currentDate = dayjs();
//     const diff = renewalDate.diff(currentDate, "hour");
//     if (diff <= 0) {
//         return <span style={{ color: "red" }}>Renewal overdue</span>;
//     }
//     const days = Math.floor(diff / 24);
//     const hours = diff % 24;
//     if (days > 0) {
//         return `${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""
//             } `;
//     } else {
//         return `${hours} hour${hours > 1 ? "s" : ""} `;
//     }
// }

export function toCamelCase(text) {
    // Remove leading and trailing white spaces
    text = text.trim();

    // Split the text into words
    let words = text.split(/[\s-]+/);

    // Convert the first letter of each word to uppercase except for the first word
    let camelCaseText = words[0].toLowerCase();
    for (let i = 1; i < words.length; i++) {
        camelCaseText +=
            words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    return camelCaseText;
}

export function convertTime(value) {
    const microseconds = value;
    if (microseconds >= 60000000) {
        const minutes = microseconds / 60000000;
        return `${minutes?.toFixed(2)} min`;
    }
    if (microseconds >= 1000000) {
        const seconds = microseconds / 1000000;
        return `${seconds?.toFixed(2)} sec`;
    }
    if (microseconds >= 1000) {
        const milliseconds = microseconds / 1000;
        return `${milliseconds?.toFixed(2)} ms`;
    }
    return `${microseconds?.toFixed(2)} μs`;
}

// export function getOptionsFromCache() {
//     let options = JSON.parse(
//         localStorage.getItem("autocompleteOptions") || "[]"
//     );
//     if (!options.includes(wallet)) {
//         options.push(wallet);
//         localStorage.setItem("autocompleteOptions", JSON.stringify(options));
//     }
//     return options;
// }

// export function updateOptionsCache(options) {
//     localStorage.setItem("autocompleteOptions", JSON.stringify(options));
// }
// export const handleDeleteOption = (option) => {
//     const updatedOptions = options.filter((item) => item !== option);
//     setOptions(updatedOptions);
//     updateOptionsCache(updatedOptions);
// };

// export const handleDeleteAllOptions = () => {
//     setOptions([]);
//     updateOptionsCache([]);
//     setwallet1("");
//     localStorage.removeItem("autocompleteOptions");
// };