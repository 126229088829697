import React, {useState} from 'react';
import {Box, IconButton} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import Menus from '../menu/menu';
import {ROUTES} from '../../../global/constant';
import {useNavigate} from 'react-router-dom';
import {Avatar, Typography, Popover, Menu, MenuItem, AppBar, Toolbar} from '@mui/material';

function MobileFooter({ual, loggedInUser, handleMenuClick, menuOpen, handleMenuCloses}) {
    const wallet = ual?.activeUser?.accountName ?? "user"
    const initials = () => {
        try {
            if (wallet) return (wallet[0] + wallet[1])?.toUpperCase();
        } catch (error) {
            console.error(error);
        }
        return "user";
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        ual.logout();
        handleMenuClose();
    };
    const handleLogin = () => {
        ual.logout();
        ual?.showModal();
        handleMenuClose();
    };
    const navigate = useNavigate();
    return (
        <Box
            className="hidden-on-lg"
            style={{
                position: "fixed",
                bottom: "0",
                left: "0",
                minHeight: "2rem",
                width: "100%",
                background: "var(--primary)",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                zIndex: "1",
            }}
        >
            <HomeIcon style={{color: "white"}} onClick={() => navigate(ROUTES.HOME)} />
            <IconButton onClick={handleMenuClick}>
                <MenuIcon style={{color: 'white'}} />
            </IconButton>
            {menuOpen && <Menus ual={ual} loggedInUser={loggedInUser} activeItem='TraceMyWax' onItemClick={handleMenuCloses} onClose={handleMenuCloses} />}
            {!loggedInUser ? (
                <LoginIcon style={{color: 'white'}} onClick={handleLogin} />
            ) : (
                <div>
                    <Avatar
                        style={{height: "1.8rem", width: "1.8rem", marginRight: "1rem"}}
                        onClick={handleAvatarClick}
                    >
                        {initials()}
                    </Avatar>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                width: "200px",
                                height: "auto",
                                background: "#1f0633",
                                marginTop: '1rem'
                            }
                        }}

                    >
                        <MenuItem style={{display: 'flex', justifyContent: 'center', background: '#1f0633'}}>{wallet.toUpperCase()}</MenuItem>

                        <hr style={{borderColor: "#c2c0c0", marginRight: '1rem', marginLeft: '1rem'}}></hr>
                        <MenuItem style={{background: '#1f0633'}} onClick={handleLogin}>Switch Account</MenuItem>
                        <MenuItem style={{background: '#1f0633'}} onClick={() => window.open('https://discord.com/invite/pnQaaMaps3', '_blank')}>
                            Help
                        </MenuItem>
                        <hr style={{borderColor: "#c2c0c0", marginRight: '1rem', marginLeft: '1rem'}}></hr>
                        <MenuItem style={{background: '#1f0633'}} onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            )}
        </Box>
    );
}

export default MobileFooter;