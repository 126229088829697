import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {UALProvider, withUAL} from 'ual-reactjs-renderer'
import {Wax} from '@eosdacio/ual-wax'
import {Anchor} from "ual-anchor";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from "@mui/material/Box";
import {Wombat} from "wombat-ual";

export const themeOptions = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#13031c',
            golden: '#CB8E16',
        },
        secondary: {
            main: '#a7761f',
            contrastText: '#c490f3',
            light: '#cbcbcb',

        },
        background: {
            default: '#13031c',
            paper: 'rgba(29,3,47,0.4)',
        },
        text: {
            primary: 'rgba(255,255,255,1)',
            disabled: 'rgba(255,255,255,0.32)',
        },
        flex: {
            display: 'flex'
        },
        large: {
            height: '45px',
        }
    },
    typography:
    {
        goldenH1: {
            fontSize: '2.625rem', // 42px ÷ 16px = 2.625rem
            color: '#a7761f',
            fontWeight: 'bold',
            textalign: 'center',
        },
        goldenH2: {
            fontSize: '2rem', // 42px ÷ 16px = 2.625rem
            color: '#a7761f',
            fontWeight: 'bold',
            textalign: 'center',
        },
    },
    components: {
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(29,3,47,0.4)',
                    border: '1px solid #c490f3',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    padding: '5px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    background: '#4e0280',
                    border: '3px #5E4991 solid',
                    '&:hover': {
                        background: '#40006A',
                    }
                },
            },
            outlined: {
                border: '#4b2861 2px solid',
                borderRadius: 8,
                textTransform: 'none',
                padding: '10px',
                margin: '5px',
                color: 'white',
                '&:hover': {
                    border: '#4b2861 2px solid',
                },
                backgroundColor: '#230838',
            },
        },
    }
},
);



//TODO make this better
const myChain = {
    chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    rpcEndpoints: [{
        protocol: "https",
        host: "query.3dkrender.com",
        port: "",
    }]
}
const lWax = new Wax([myChain], {appName: 'waxptools'})
const lAnchor = new Anchor([myChain], {appName: 'waxptools'})
const lWombat = new Wombat([myChain], {appName: 'waxptools'})
// const lOreid = new OreIdAuthenticator([myChain], { appName: 'waxptools' })

const MyUALConsumer = withUAL(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <React.StrictMode>
        <BrowserRouter>
            <UALProvider chains={[myChain]} authenticators={[lWax, lAnchor, lWombat]} appName={'waxptools'}>
                <ThemeProvider theme={themeOptions}>
                    <Box backgroundColor='primary.main index'>
                        <MyUALConsumer />
                    </Box>

                </ThemeProvider>
            </UALProvider>
        </BrowserRouter>

    </React.StrictMode>
);
