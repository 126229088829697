import React from 'react';
import {Autocomplete, FormControl, Grid, TextField, Typography} from '@mui/material';
import './transactions.scss'

const AutocompleteWithLabels = ({label, options, onOptionSelected}) => {
    const handleOptionChange = (event, value) => {
        onOptionSelected(value);
    };

    return (
        <Grid className='check' container spacing={2}>
            <Grid item xs={12} sm={12} className='custom-grid-item'>
                <Grid container spacing={1} alignItems="center" xs={12}>
                    <Grid item xs={4}>
                        <Typography variant="h6">{label}:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl variant="filled" fullWidth>
                            <Autocomplete
                                size='small'
                                options={options}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => <TextField {...params} label={label} variant="filled" style={{width: '100%'}} />}
                                onChange={handleOptionChange}
                                defaultValue={options[0]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AutocompleteWithLabels;